/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Pricing } from './Pricing';
import {
    PricingFromJSON,
    PricingFromJSONTyped,
    PricingToJSON,
} from './Pricing';

/**
 * 
 * @export
 * @interface PricingSchedule
 */
export interface PricingSchedule {
    /**
     * 
     * @type {string}
     * @memberof PricingSchedule
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PricingSchedule
     */
    dateFrom: string;
    /**
     * 
     * @type {string}
     * @memberof PricingSchedule
     */
    dateTo?: string | null;
    /**
     * 
     * @type {Array<Pricing>}
     * @memberof PricingSchedule
     */
    pricings: Array<Pricing>;
}

/**
 * Check if a given object implements the PricingSchedule interface.
 */
export function instanceOfPricingSchedule(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "dateFrom" in value;
    isInstance = isInstance && "pricings" in value;

    return isInstance;
}

export function PricingScheduleFromJSON(json: any): PricingSchedule {
    return PricingScheduleFromJSONTyped(json, false);
}

export function PricingScheduleFromJSONTyped(json: any, ignoreDiscriminator: boolean): PricingSchedule {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'dateFrom': json['dateFrom'],
        'dateTo': !exists(json, 'dateTo') ? undefined : json['dateTo'],
        'pricings': ((json['pricings'] as Array<any>).map(PricingFromJSON)),
    };
}

export function PricingScheduleToJSON(value?: PricingSchedule | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'dateFrom': value.dateFrom,
        'dateTo': value.dateTo,
        'pricings': ((value.pricings as Array<any>).map(PricingToJSON)),
    };
}

