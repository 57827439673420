/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ReservationPickupType } from './ReservationPickupType';
import {
    ReservationPickupTypeFromJSON,
    ReservationPickupTypeFromJSONTyped,
    ReservationPickupTypeToJSON,
} from './ReservationPickupType';

/**
 * 
 * @export
 * @interface Pickup
 */
export interface Pickup {
    /**
     * 
     * @type {ReservationPickupType}
     * @memberof Pickup
     */
    type: ReservationPickupType;
    /**
     * 
     * @type {string}
     * @memberof Pickup
     */
    location: string | null;
    /**
     * 
     * @type {string}
     * @memberof Pickup
     */
    description: string | null;
    /**
     * Time format: h:mm a
     * @type {string}
     * @memberof Pickup
     */
    time: string | null;
}

/**
 * Check if a given object implements the Pickup interface.
 */
export function instanceOfPickup(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "location" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "time" in value;

    return isInstance;
}

export function PickupFromJSON(json: any): Pickup {
    return PickupFromJSONTyped(json, false);
}

export function PickupFromJSONTyped(json: any, ignoreDiscriminator: boolean): Pickup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': ReservationPickupTypeFromJSON(json['type']),
        'location': json['location'],
        'description': json['description'],
        'time': json['time'],
    };
}

export function PickupToJSON(value?: Pickup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': ReservationPickupTypeToJSON(value.type),
        'location': value.location,
        'description': value.description,
        'time': value.time,
    };
}

