/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Image } from './Image';
import {
    ImageFromJSON,
    ImageFromJSONTyped,
    ImageToJSON,
} from './Image';
import type { Languages } from './Languages';
import {
    LanguagesFromJSON,
    LanguagesFromJSONTyped,
    LanguagesToJSON,
} from './Languages';
import type { TimeAvailabilityPricing } from './TimeAvailabilityPricing';
import {
    TimeAvailabilityPricingFromJSON,
    TimeAvailabilityPricingFromJSONTyped,
    TimeAvailabilityPricingToJSON,
} from './TimeAvailabilityPricing';

/**
 * 
 * @export
 * @interface OptionAvailabilityPricing
 */
export interface OptionAvailabilityPricing {
    /**
     * 
     * @type {string}
     * @memberof OptionAvailabilityPricing
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof OptionAvailabilityPricing
     */
    onlyVisibleWhenAuthenticated?: boolean;
    /**
     * 
     * @type {Languages}
     * @memberof OptionAvailabilityPricing
     */
    name: Languages;
    /**
     * 
     * @type {Languages}
     * @memberof OptionAvailabilityPricing
     */
    description: Languages;
    /**
     * 
     * @type {Image}
     * @memberof OptionAvailabilityPricing
     */
    optionImage?: Image;
    /**
     * 
     * @type {number}
     * @memberof OptionAvailabilityPricing
     */
    maxTravelersPerOption: number | null;
    /**
     * 
     * @type {number}
     * @memberof OptionAvailabilityPricing
     */
    travelersCount: number;
    /**
     * 
     * @type {Array<TimeAvailabilityPricing>}
     * @memberof OptionAvailabilityPricing
     */
    timeAvailabilityPricings: Array<TimeAvailabilityPricing>;
}

/**
 * Check if a given object implements the OptionAvailabilityPricing interface.
 */
export function instanceOfOptionAvailabilityPricing(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "maxTravelersPerOption" in value;
    isInstance = isInstance && "travelersCount" in value;
    isInstance = isInstance && "timeAvailabilityPricings" in value;

    return isInstance;
}

export function OptionAvailabilityPricingFromJSON(json: any): OptionAvailabilityPricing {
    return OptionAvailabilityPricingFromJSONTyped(json, false);
}

export function OptionAvailabilityPricingFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionAvailabilityPricing {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'onlyVisibleWhenAuthenticated': !exists(json, 'onlyVisibleWhenAuthenticated') ? undefined : json['onlyVisibleWhenAuthenticated'],
        'name': LanguagesFromJSON(json['name']),
        'description': LanguagesFromJSON(json['description']),
        'optionImage': !exists(json, 'optionImage') ? undefined : ImageFromJSON(json['optionImage']),
        'maxTravelersPerOption': json['maxTravelersPerOption'],
        'travelersCount': json['travelersCount'],
        'timeAvailabilityPricings': ((json['timeAvailabilityPricings'] as Array<any>).map(TimeAvailabilityPricingFromJSON)),
    };
}

export function OptionAvailabilityPricingToJSON(value?: OptionAvailabilityPricing | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'onlyVisibleWhenAuthenticated': value.onlyVisibleWhenAuthenticated,
        'name': LanguagesToJSON(value.name),
        'description': LanguagesToJSON(value.description),
        'optionImage': ImageToJSON(value.optionImage),
        'maxTravelersPerOption': value.maxTravelersPerOption,
        'travelersCount': value.travelersCount,
        'timeAvailabilityPricings': ((value.timeAvailabilityPricings as Array<any>).map(TimeAvailabilityPricingToJSON)),
    };
}

