import React from "react";
import styled, { css, DefaultTheme } from "styled-components";
import Ellipsis from "./Ellipsis";
import { Tooltip } from "antd";

interface Props {
  children: React.ReactNode;
  level?: Variant;
  align?: string;
  fontcolor?: (theme: DefaultTheme) => string;
  ellipsis?: boolean;
  tooltip?: boolean;
  fontWeight?: FontWeight;
  margin?: string;
}

type Variant = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;

type FontSize =
  | "xxx_large"
  | "xx_large"
  | "x_large"
  | "large"
  | "medium"
  | "small"
  | "x_small"
  | "xx_small";

type FontWeight = "small" | "medium" | "large";

const FONT_SIZES: Record<Variant, FontSize> = {
  1: "xxx_large",
  2: "xx_large",
  3: "x_large",
  4: "large",
  5: "medium",
  6: "small",
  7: "x_small",
  8: "xx_small",
};

type TAG = "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "span";

const TAGS: Record<Variant, TAG> = {
  1: "h1",
  2: "h2",
  3: "h3",
  4: "h4",
  5: "h5",
  6: "h6",
  7: "span",
  8: "span",
};

export const Title = ({
  level = 1,
  align = "left",
  ellipsis = false,
  tooltip = false,
  fontWeight = "large",
  children,
  margin,
}: Props): JSX.Element => {
  const fontSize = FONT_SIZES[level];

  const tag = TAGS[level];

  return (
    <Container
      as={tag}
      fontSize={fontSize}
      align={align}
      fontWeight={fontWeight}
      margin={margin}
    >
      <Ellipsis ellipsis={ellipsis}>
        <Tooltip title={tooltip ? children : ""} color="black">
          {children}
        </Tooltip>
      </Ellipsis>
    </Container>
  );
};

interface ContainerProps {
  fontSize: FontSize;
  align: string;
  fontWeight: FontWeight;
  margin?: string;
}

const Container = styled.div<ContainerProps>`
  ${({ fontSize, align, fontWeight, theme, margin }) => css`
    width: auto;
    color: ${theme.colors.black};
    font-size: ${theme.font_sizes[fontSize]};
    font-weight: ${theme.font_weight[fontWeight]};
    text-align: ${align};
    margin: ${margin};

    .ant-typography,
    .ant-typography p {
      margin-bottom: 0;
    }
  `}
`;
