type Args = (ObjectType | string)[];

export const classNames = (...args: Args): string =>
  args
    .map((arg) =>
      typeof arg === "object"
        ? Object.entries(arg)
            .filter(([, condition]) => condition)
            .map(([className]) => className)
            .join(" ")
        : arg
    )
    .join(" ");
