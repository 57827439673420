import React from "react";
import styled from "styled-components";
import { orderBy } from "lodash";
import { useAuthentication, useOperator } from "../../../providers";
import assert from "assert";
import { Select } from "antd";
import { mediaQuery } from "../../../styles";
import { useLocation, useNavigate } from "react-router-dom";
import UrlAssembler from "url-assembler";
import { replaceOperatorIdInPath } from "../../../utils";

export const PrivateOperatorSelector = (): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const { authUser } = useAuthentication();
  const { currentOperator, onChangeOperator } = useOperator();

  const userOperatorsView = orderBy(authUser?.operators, ["name"]);

  const onChangeOperatorId = (
    operatorId: string | undefined | unknown
  ): void => {
    const userOperator = userOperatorsView.find(
      (operator) => operator.id === operatorId
    );
    assert(userOperator, "userOperator missing!");

    onChangeOperator(userOperator.id);

    const pathname = new UrlAssembler()
      .template(replaceOperatorIdInPath(location.pathname))
      .param({
        operatorId: operatorId,
      })
      .toString();

    navigate(pathname);
  };

  return (
    <SelectStyled
      showSearch
      bordered={false}
      value={currentOperator?.id}
      onChange={onChangeOperatorId}
      filterOption={(input, option) =>
        option?.label.toUpperCase().indexOf(input.toUpperCase()) === 0
      }
      options={userOperatorsView.map((operator) => ({
        label: operator.name,
        value: operator.id,
      }))}
    />
  );
};

const SelectStyled = styled(Select)`
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  display: flex;
  align-items: center;
  border: none;
  min-width: 200px;
  font-size: 0.7rem;
  .ant-select-selector {
    padding: 0;
  }
  .ant-select-arrow {
    color: black;
  }

  ${mediaQuery.minTablet} {
    width: auto;
    font-size: 1rem;
  }
`;
