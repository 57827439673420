import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuthentication, useLanguage } from "../providers";
import { Spinner } from "../components";

export const PrivateHostRoute = (): JSX.Element => {
  const { authUser } = useAuthentication();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const { currentLanguageCode } = useLanguage();

  useEffect(() => {
    if (authUser && authUser.roleCode === "host") {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }

    setIsLoading(false);
  }, [authUser?.id]);

  if (isLoading) {
    return <Spinner />;
  }

  return isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate to={`/${currentLanguageCode}/`} />
  );
};
