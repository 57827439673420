/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ContactPhone
 */
export interface ContactPhone {
    /**
     * 
     * @type {string}
     * @memberof ContactPhone
     */
    number: string;
    /**
     * 
     * @type {string}
     * @memberof ContactPhone
     */
    prefix: string;
}

/**
 * Check if a given object implements the ContactPhone interface.
 */
export function instanceOfContactPhone(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "number" in value;
    isInstance = isInstance && "prefix" in value;

    return isInstance;
}

export function ContactPhoneFromJSON(json: any): ContactPhone {
    return ContactPhoneFromJSONTyped(json, false);
}

export function ContactPhoneFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactPhone {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'number': json['number'],
        'prefix': json['prefix'],
    };
}

export function ContactPhoneToJSON(value?: ContactPhone | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'number': value.number,
        'prefix': value.prefix,
    };
}

