/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FirebaseFirestoreTimestamp } from './FirebaseFirestoreTimestamp';
import {
    FirebaseFirestoreTimestampFromJSON,
    FirebaseFirestoreTimestampFromJSONTyped,
    FirebaseFirestoreTimestampToJSON,
} from './FirebaseFirestoreTimestamp';

/**
 * 
 * @export
 * @interface EmailSent
 */
export interface EmailSent {
    /**
     * 
     * @type {FirebaseFirestoreTimestamp}
     * @memberof EmailSent
     */
    createAt: FirebaseFirestoreTimestamp;
    /**
     * 
     * @type {boolean}
     * @memberof EmailSent
     */
    status: boolean;
}

/**
 * Check if a given object implements the EmailSent interface.
 */
export function instanceOfEmailSent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "createAt" in value;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function EmailSentFromJSON(json: any): EmailSent {
    return EmailSentFromJSONTyped(json, false);
}

export function EmailSentFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailSent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createAt': FirebaseFirestoreTimestampFromJSON(json['createAt']),
        'status': json['status'],
    };
}

export function EmailSentToJSON(value?: EmailSent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createAt': FirebaseFirestoreTimestampToJSON(value.createAt),
        'status': value.status,
    };
}

