/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Einvoicing } from './Einvoicing';
import {
    EinvoicingFromJSON,
    EinvoicingFromJSONTyped,
    EinvoicingToJSON,
} from './Einvoicing';
import type { LanguageCode } from './LanguageCode';
import {
    LanguageCodeFromJSON,
    LanguageCodeFromJSONTyped,
    LanguageCodeToJSON,
} from './LanguageCode';
import type { PaymentType } from './PaymentType';
import {
    PaymentTypeFromJSON,
    PaymentTypeFromJSONTyped,
    PaymentTypeToJSON,
} from './PaymentType';
import type { Pickup } from './Pickup';
import {
    PickupFromJSON,
    PickupFromJSONTyped,
    PickupToJSON,
} from './Pickup';

/**
 * 
 * @export
 * @interface PutAdditionalInfoBody
 */
export interface PutAdditionalInfoBody {
    /**
     * 
     * @type {Einvoicing}
     * @memberof PutAdditionalInfoBody
     */
    einvoicing?: Einvoicing;
    /**
     * 
     * @type {Pickup}
     * @memberof PutAdditionalInfoBody
     */
    pickup: Pickup;
    /**
     * 
     * @type {PaymentType}
     * @memberof PutAdditionalInfoBody
     */
    paymentType: PaymentType;
    /**
     * 
     * @type {LanguageCode}
     * @memberof PutAdditionalInfoBody
     */
    languageCode: LanguageCode;
    /**
     * 
     * @type {boolean}
     * @memberof PutAdditionalInfoBody
     */
    acceptedTermsAndConditions: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PutAdditionalInfoBody
     */
    acceptedChangeAndCancellationPolicies: boolean;
}

/**
 * Check if a given object implements the PutAdditionalInfoBody interface.
 */
export function instanceOfPutAdditionalInfoBody(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "pickup" in value;
    isInstance = isInstance && "paymentType" in value;
    isInstance = isInstance && "languageCode" in value;
    isInstance = isInstance && "acceptedTermsAndConditions" in value;
    isInstance = isInstance && "acceptedChangeAndCancellationPolicies" in value;

    return isInstance;
}

export function PutAdditionalInfoBodyFromJSON(json: any): PutAdditionalInfoBody {
    return PutAdditionalInfoBodyFromJSONTyped(json, false);
}

export function PutAdditionalInfoBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutAdditionalInfoBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'einvoicing': !exists(json, 'einvoicing') ? undefined : EinvoicingFromJSON(json['einvoicing']),
        'pickup': PickupFromJSON(json['pickup']),
        'paymentType': PaymentTypeFromJSON(json['paymentType']),
        'languageCode': LanguageCodeFromJSON(json['languageCode']),
        'acceptedTermsAndConditions': json['acceptedTermsAndConditions'],
        'acceptedChangeAndCancellationPolicies': json['acceptedChangeAndCancellationPolicies'],
    };
}

export function PutAdditionalInfoBodyToJSON(value?: PutAdditionalInfoBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'einvoicing': EinvoicingToJSON(value.einvoicing),
        'pickup': PickupToJSON(value.pickup),
        'paymentType': PaymentTypeToJSON(value.paymentType),
        'languageCode': LanguageCodeToJSON(value.languageCode),
        'acceptedTermsAndConditions': value.acceptedTermsAndConditions,
        'acceptedChangeAndCancellationPolicies': value.acceptedChangeAndCancellationPolicies,
    };
}

