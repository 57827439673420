/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PaymentToken } from './PaymentToken';
import {
    PaymentTokenFromJSON,
    PaymentTokenFromJSONTyped,
    PaymentTokenToJSON,
} from './PaymentToken';

/**
 * Make all properties in T optional
 * @export
 * @interface PartialRecordStripeCheckoutSessionOrStripePaymentIntentPaymentTokenOrNull
 */
export interface PartialRecordStripeCheckoutSessionOrStripePaymentIntentPaymentTokenOrNull {
    /**
     * 
     * @type {PaymentToken}
     * @memberof PartialRecordStripeCheckoutSessionOrStripePaymentIntentPaymentTokenOrNull
     */
    stripeCheckoutSession?: PaymentToken;
    /**
     * 
     * @type {PaymentToken}
     * @memberof PartialRecordStripeCheckoutSessionOrStripePaymentIntentPaymentTokenOrNull
     */
    stripePaymentIntent?: PaymentToken;
}

/**
 * Check if a given object implements the PartialRecordStripeCheckoutSessionOrStripePaymentIntentPaymentTokenOrNull interface.
 */
export function instanceOfPartialRecordStripeCheckoutSessionOrStripePaymentIntentPaymentTokenOrNull(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PartialRecordStripeCheckoutSessionOrStripePaymentIntentPaymentTokenOrNullFromJSON(json: any): PartialRecordStripeCheckoutSessionOrStripePaymentIntentPaymentTokenOrNull {
    return PartialRecordStripeCheckoutSessionOrStripePaymentIntentPaymentTokenOrNullFromJSONTyped(json, false);
}

export function PartialRecordStripeCheckoutSessionOrStripePaymentIntentPaymentTokenOrNullFromJSONTyped(json: any, ignoreDiscriminator: boolean): PartialRecordStripeCheckoutSessionOrStripePaymentIntentPaymentTokenOrNull {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'stripeCheckoutSession': !exists(json, 'stripeCheckoutSession') ? undefined : PaymentTokenFromJSON(json['stripeCheckoutSession']),
        'stripePaymentIntent': !exists(json, 'stripePaymentIntent') ? undefined : PaymentTokenFromJSON(json['stripePaymentIntent']),
    };
}

export function PartialRecordStripeCheckoutSessionOrStripePaymentIntentPaymentTokenOrNullToJSON(value?: PartialRecordStripeCheckoutSessionOrStripePaymentIntentPaymentTokenOrNull | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'stripeCheckoutSession': PaymentTokenToJSON(value.stripeCheckoutSession),
        'stripePaymentIntent': PaymentTokenToJSON(value.stripePaymentIntent),
    };
}

