import React, { memo } from "react";
import { Typography } from "antd";

const { Paragraph } = Typography;

interface Props {
  children?: React.ReactNode;
  ellipsis?: boolean;
}

const Ellipsis = ({ children, ellipsis = true }: Props): JSX.Element => {
  return (
    <Paragraph style={{ marginBottom: "0" }} ellipsis={ellipsis}>
      {children}
    </Paragraph>
  );
};

export default memo(Ellipsis);
