import { get, replace } from "lodash";
import { useLanguage } from "../providers";
import { loadTranslation } from "../config/i18n";
import { useEffect, useState } from "react";

type LanguageTranslations = Partial<Record<LanguageCode, Translations>>;

export const useI18n = (): Translate => {
  const [translations, setTranslations] = useState<LanguageTranslations>({});
  const { currentLanguageCode } = useLanguage();

  useEffect(() => {
    let isMounted = true;

    (async () => {
      if (!translations[currentLanguageCode]) {
        const loadedTranslations = await loadTranslation(currentLanguageCode);
        if (isMounted) {
          setTranslations((prevTranslations) => ({
            ...prevTranslations,
            [currentLanguageCode]: loadedTranslations,
          }));
        }
      }
    })();

    return () => {
      isMounted = false;
    };
  }, [currentLanguageCode]);

  return (key: string, params?: string[], defaultTranslation = ""): string => {
    let text = get(translations[currentLanguageCode], key, defaultTranslation);

    params?.forEach((param, index) => {
      const regex = new RegExp("<<" + index + ">>", "g");

      text = replace(text, regex, param);
    });
    return text;
  };
};
