import React from "react";
import styled, { css, DefaultTheme } from "styled-components";
import { isEmpty, toString } from "lodash";
import { classNames, keyframes } from "../styles";
import { Typography } from "antd";
import { lighten } from "polished";
import { Moment } from "moment";

const { Text } = Typography;

interface Props extends Partial<StyledProps> {
  children?: React.ReactNode;
  helperText?: string;
  label?: string;
  value?:
    | Record<string, unknown>
    | null
    | []
    | boolean
    | string
    | string[]
    | number
    | Moment;
  componentId?: string;
  dataTestId?: string;
}

export const Outlined = ({
  animation = false,
  hidden = false,
  children,
  disabled = false,
  error = false,
  helperText,
  label,
  required = false,
  value,
  componentId,
  dataTestId,
  bordered = true,
}: Props): JSX.Element => (
  <Wrapper hidden={hidden}>
    <Container
      hasValue={typeof value === "object" ? !isEmpty(value) : !!toString(value)}
      error={error}
      required={required}
      animation={animation}
      className={classNames({ "scroll-error-anchor": error })}
      disabled={disabled}
      data-testid={dataTestId}
      bordered={!!bordered}
    >
      <div className="item-wrapper">{children}</div>
      <label htmlFor={componentId} className="item-label">
        {label}
      </label>
    </Container>
    {helperText && (
      <ErrorItem
        fontColor={(theme) => (error ? theme.colors.error : undefined)}
        fontSize="small"
        error={error}
      >
        {helperText}
      </ErrorItem>
    )}
  </Wrapper>
);

type StyledProps = ContainerProps & WrapperProps;

interface ContainerProps {
  animation: boolean;
  disabled: boolean;
  error: boolean;
  required: boolean;
  hasValue: boolean;
  bordered: boolean;
}

interface WrapperProps {
  hidden: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  ${({ hidden }) => css`
    margin-top: 5px;

    ${hidden &&
    css`
      display: none;
    `}
  `}
`;

const labelAnimate = css`
  padding: 0 5px;
  border-radius: ${({ theme }) => theme.border_radius.xx_small};
  top: -11px;
  left: 6px;
  bottom: auto;
  font-weight: 600;
  font-size: ${({ theme }) => theme.font_sizes.x_small};
  background-color: ${({ theme }) => theme.colors.white};
`;

const Container = styled.div<ContainerProps>`
  ${({
    theme,
    error,
    required,
    disabled,
    hasValue,
    animation,
    bordered,
  }) => css`
    position: relative;
    width: inherit;
    border-radius: ${theme.border_radius.xxx_small};
    background: ${disabled ? "#f5f5f5" : theme.colors.white};
    ${bordered &&
    css`
      border: 1px solid ${error ? theme.colors.error : theme.colors.bordered};
    `}
    animation: ${error && keyframes.shake} 340ms
      cubic-bezier(0.36, 0.07, 0.19, 0.97) both;

    &:hover,
    &:focus-within {
      border-color: ${error
        ? theme.colors.error
        : disabled
        ? lighten(0.04, theme.colors.bordered)
        : lighten(0.1, theme.colors.dark)};
    }

    .item-label {
      position: absolute;
      top: 0;
      left: 10px;
      bottom: 0;
      z-index: 100;
      pointer-events: none;
      display: flex;
      align-items: center;
      background-color: transparent;
      color: ${error ? theme.colors.error : "#1a1a1a"};
      font-size: ${theme.font_sizes.small};
      transition: all ease-in-out 150ms, opacity 150ms;

      ${animation && labelAnimate};

      ${hasValue && labelAnimate};

      ${required &&
      css`
        ::after {
          display: inline-block;
          margin-left: 0.2rem;
          color: ${error ? theme.colors.error : "#1a1a1a"};
          font-size: ${({ theme }) => theme.font_sizes.small};
          line-height: 1;
          content: "*";
        }
      `}
    }

    .item-wrapper {
      &:hover + .item-label,
      &:hover + .item-label:after {
        color: ${error
          ? theme.colors.error
          : disabled
          ? "#1a1a1a"
          : lighten(0.1, "#1a1a1a")};
      }

      &:focus-within + .item-label,
      &:-webkit-autofill + .item-label {
        ${labelAnimate};

        color: ${error ? theme.colors.error : lighten(0.1, "#1a1a1a")};

        ${error &&
        css`
          color: ${theme.colors.error};
        `}
        &:after {
          color: ${error ? theme.colors.error : lighten(0.1, "#1a1a1a")};
        }
      }

      input:-webkit-autofill {
        -webkit-text-fill-color: #fff;
        ${hasValue &&
        css`
          -webkit-text-fill-color: #1a1a1a;
        `};

        &:focus {
          -webkit-text-fill-color: #1a1a1a;
        }
      }

      //Styles default
      .ant-input-number,
      .ant-picker,
      .ant-select {
        width: 100%;
        box-shadow: none;
        outline: none;
      }

      .ant-input-affix-wrapper,
      .ant-input {
        box-shadow: none;
      }

      .ant-input-group-addon {
        border: 0 solid #d9d9d9;
        border-left: 1px solid #d9d9d9;
      }
    }
  `}
`;

interface ErrorItemProps {
  fontColor?: (theme: DefaultTheme) => string | undefined;
  fontSize:
    | "xx_small"
    | "x_small"
    | "small"
    | "medium"
    | "large"
    | "x_large"
    | "xx_large"
    | "xxx_large";
  error: boolean;
}

const ErrorItem = styled(Text)<ErrorItemProps>`
  ${({ theme, fontColor, fontSize = "small", error }) => css`
    font-size: ${theme.font_sizes[fontSize]};
    ${fontColor &&
    css`
      color: ${fontColor(theme)};
    `},
    ${error &&
    css`
      animation: ${keyframes.shake} 340ms;
    `}
  `}
`;
