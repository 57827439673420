import React, { createContext, useContext, useEffect } from "react";
import { useCollectionData } from "../hooks/firebase";
import { notification, Spinner } from "../components";
import { orderBy } from "lodash";
import { useOperator } from "./OperatorProvider";
import { where } from "firebase/firestore";

interface Context {
  tours: Tour[];
}

const GlobalOperatorDataContext = createContext<Context>({
  tours: [],
});

interface Props {
  children: JSX.Element;
}

export const GlobalOperatorDataProvider = ({
  children,
}: Props): JSX.Element => {
  const { currentOperator } = useOperator();

  const [tours = [], toursLoading, toursError] = useCollectionData<Tour>(
    "onSnapshot",
    "tours",
    [
      where("operatorId", "==", currentOperator?.id),
      where("isDeleted", "==", false),
    ],
    [currentOperator?.id],
    !currentOperator?.id
  );

  useEffect(() => {
    toursError && notification({ type: "error" });
  }, [toursError]);

  if (toursLoading && location.pathname !== "/") return <Spinner fullscreen />;

  return (
    <GlobalOperatorDataContext.Provider
      value={{
        tours: orderBy(tours, (tour) => [tour.createAt], ["asc"]),
      }}
    >
      {children}
    </GlobalOperatorDataContext.Provider>
  );
};

export const useGlobalOperatorData = (): Context =>
  useContext(GlobalOperatorDataContext);
