/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const Day = {
    Mon: 'mon',
    Tue: 'tue',
    Wed: 'wed',
    Thu: 'thu',
    Fri: 'fri',
    Sat: 'sat',
    Sun: 'sun'
} as const;
export type Day = typeof Day[keyof typeof Day];


export function DayFromJSON(json: any): Day {
    return DayFromJSONTyped(json, false);
}

export function DayFromJSONTyped(json: any, ignoreDiscriminator: boolean): Day {
    return json as Day;
}

export function DayToJSON(value?: Day | null): any {
    return value as any;
}

