/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { City } from './City';
import {
    CityFromJSON,
    CityFromJSONTyped,
    CityToJSON,
} from './City';

/**
 * 
 * @export
 * @interface PartialTourDropOff
 */
export interface PartialTourDropOff {
    /**
     * 
     * @type {City}
     * @memberof PartialTourDropOff
     */
    city: City;
    /**
     * 
     * @type {Array<string>}
     * @memberof PartialTourDropOff
     */
    dropOffLocationsIds?: Array<string>;
}

/**
 * Check if a given object implements the PartialTourDropOff interface.
 */
export function instanceOfPartialTourDropOff(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "city" in value;

    return isInstance;
}

export function PartialTourDropOffFromJSON(json: any): PartialTourDropOff {
    return PartialTourDropOffFromJSONTyped(json, false);
}

export function PartialTourDropOffFromJSONTyped(json: any, ignoreDiscriminator: boolean): PartialTourDropOff {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'city': CityFromJSON(json['city']),
        'dropOffLocationsIds': !exists(json, 'dropOffLocationsIds') ? undefined : json['dropOffLocationsIds'],
    };
}

export function PartialTourDropOffToJSON(value?: PartialTourDropOff | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'city': CityToJSON(value.city),
        'dropOffLocationsIds': value.dropOffLocationsIds,
    };
}

