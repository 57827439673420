/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Pickup } from './Pickup';
import {
    PickupFromJSON,
    PickupFromJSONTyped,
    PickupToJSON,
} from './Pickup';

/**
 * 
 * @export
 * @interface PostSetPickupLocationBody
 */
export interface PostSetPickupLocationBody {
    /**
     * 
     * @type {Pickup}
     * @memberof PostSetPickupLocationBody
     */
    pickup: Pickup;
    /**
     * 
     * @type {boolean}
     * @memberof PostSetPickupLocationBody
     */
    sendEmail: boolean;
}

/**
 * Check if a given object implements the PostSetPickupLocationBody interface.
 */
export function instanceOfPostSetPickupLocationBody(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "pickup" in value;
    isInstance = isInstance && "sendEmail" in value;

    return isInstance;
}

export function PostSetPickupLocationBodyFromJSON(json: any): PostSetPickupLocationBody {
    return PostSetPickupLocationBodyFromJSONTyped(json, false);
}

export function PostSetPickupLocationBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostSetPickupLocationBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pickup': PickupFromJSON(json['pickup']),
        'sendEmail': json['sendEmail'],
    };
}

export function PostSetPickupLocationBodyToJSON(value?: PostSetPickupLocationBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pickup': PickupToJSON(value.pickup),
        'sendEmail': value.sendEmail,
    };
}

