import React from "react";
import { PrivateHeader } from "./Private.Header";
import styled from "styled-components";
import { mediaQuery } from "../../../styles";
import { WrapperComponent } from "../../WrapperComponent";
import { Layout } from "antd";

interface Props {
  children: JSX.Element;
  wrapper?: boolean;
  showOperatorSelector?: boolean;
}

export const PrivateLayout = ({
  children,
  wrapper = true,
  showOperatorSelector = true,
}: Props): JSX.Element => (
  <LayoutStyled>
    {wrapper ? (
      <WrapperComponent paddingMb="0">
        <PrivateHeader showOperatorSelector={showOperatorSelector} />
      </WrapperComponent>
    ) : (
      <PrivateHeader showOperatorSelector={showOperatorSelector} />
    )}
    <LayoutBodyStyled>
      {wrapper ? <WrapperComponent>{children}</WrapperComponent> : children}
    </LayoutBodyStyled>
  </LayoutStyled>
);

const LayoutStyled = styled(Layout)`
  min-height: 100vh;
  height: auto;
  background-color: ${({ theme }) => theme.colors.white};

  .ant-layout-header {
    padding: 0 12px;
    box-sizing: border-box;
    background-color: ${({ theme }) => theme.colors.white};
  }
`;

const LayoutBodyStyled = styled(Layout.Content)`
  padding: 1rem;
  overflow: initial;
  background-color: ${({ theme }) => theme.colors.white};
  box-sizing: border-box;

  ${mediaQuery.minMobile} {
    padding: 1.5rem;
  }
`;
