/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Einvoicing } from './Einvoicing';
import {
    EinvoicingFromJSON,
    EinvoicingFromJSONTyped,
    EinvoicingToJSON,
} from './Einvoicing';
import type { Extra } from './Extra';
import {
    ExtraFromJSON,
    ExtraFromJSONTyped,
    ExtraToJSON,
} from './Extra';
import type { FirebaseFirestoreTimestamp } from './FirebaseFirestoreTimestamp';
import {
    FirebaseFirestoreTimestampFromJSON,
    FirebaseFirestoreTimestampFromJSONTyped,
    FirebaseFirestoreTimestampToJSON,
} from './FirebaseFirestoreTimestamp';
import type { LanguageCode } from './LanguageCode';
import {
    LanguageCodeFromJSON,
    LanguageCodeFromJSONTyped,
    LanguageCodeToJSON,
} from './LanguageCode';
import type { PartialRecordAgeIdNumber } from './PartialRecordAgeIdNumber';
import {
    PartialRecordAgeIdNumberFromJSON,
    PartialRecordAgeIdNumberFromJSONTyped,
    PartialRecordAgeIdNumberToJSON,
} from './PartialRecordAgeIdNumber';
import type { Pickup } from './Pickup';
import {
    PickupFromJSON,
    PickupFromJSONTyped,
    PickupToJSON,
} from './Pickup';
import type { PostReservationBodyContact } from './PostReservationBodyContact';
import {
    PostReservationBodyContactFromJSON,
    PostReservationBodyContactFromJSONTyped,
    PostReservationBodyContactToJSON,
} from './PostReservationBodyContact';
import type { PostReservationBodyTraveler } from './PostReservationBodyTraveler';
import {
    PostReservationBodyTravelerFromJSON,
    PostReservationBodyTravelerFromJSONTyped,
    PostReservationBodyTravelerToJSON,
} from './PostReservationBodyTraveler';
import type { PostReservationBodyUtm } from './PostReservationBodyUtm';
import {
    PostReservationBodyUtmFromJSON,
    PostReservationBodyUtmFromJSONTyped,
    PostReservationBodyUtmToJSON,
} from './PostReservationBodyUtm';

/**
 * 
 * @export
 * @interface PostReservationBody
 */
export interface PostReservationBody {
    /**
     * 
     * @type {PartialRecordAgeIdNumber}
     * @memberof PostReservationBody
     */
    tourTravelersCount: PartialRecordAgeIdNumber;
    /**
     * 
     * @type {string}
     * @memberof PostReservationBody
     */
    tourStartDate: string;
    /**
     * 
     * @type {LanguageCode}
     * @memberof PostReservationBody
     */
    languageCode: LanguageCode;
    /**
     * 
     * @type {string}
     * @memberof PostReservationBody
     */
    tourId: string;
    /**
     * 
     * @type {string}
     * @memberof PostReservationBody
     */
    optionId: string;
    /**
     * 
     * @type {string}
     * @memberof PostReservationBody
     */
    time: string;
    /**
     * 
     * @type {PostReservationBodyUtm}
     * @memberof PostReservationBody
     */
    utm: PostReservationBodyUtm | null;
    /**
     * 
     * @type {string}
     * @memberof PostReservationBody
     */
    officeId?: string;
    /**
     * 
     * @type {Array<PostReservationBodyTraveler>}
     * @memberof PostReservationBody
     */
    travelers: Array<PostReservationBodyTraveler>;
    /**
     * 
     * @type {PostReservationBodyContact}
     * @memberof PostReservationBody
     */
    contact?: PostReservationBodyContact | null;
    /**
     * 
     * @type {Array<Extra>}
     * @memberof PostReservationBody
     */
    extras?: Array<Extra>;
    /**
     * 
     * @type {Pickup}
     * @memberof PostReservationBody
     */
    pickup?: Pickup;
    /**
     * 
     * @type {Einvoicing}
     * @memberof PostReservationBody
     */
    einvoicing?: Einvoicing;
    /**
     * 
     * @type {string}
     * @memberof PostReservationBody
     */
    discountId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PostReservationBody
     */
    sendEmail?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PostReservationBody
     */
    splittedFromReservationId?: string;
    /**
     * 
     * @type {FirebaseFirestoreTimestamp}
     * @memberof PostReservationBody
     */
    bookAt?: FirebaseFirestoreTimestamp;
    /**
     * 
     * @type {string}
     * @memberof PostReservationBody
     */
    hour?: string;
}

/**
 * Check if a given object implements the PostReservationBody interface.
 */
export function instanceOfPostReservationBody(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "tourTravelersCount" in value;
    isInstance = isInstance && "tourStartDate" in value;
    isInstance = isInstance && "languageCode" in value;
    isInstance = isInstance && "tourId" in value;
    isInstance = isInstance && "optionId" in value;
    isInstance = isInstance && "time" in value;
    isInstance = isInstance && "utm" in value;
    isInstance = isInstance && "travelers" in value;

    return isInstance;
}

export function PostReservationBodyFromJSON(json: any): PostReservationBody {
    return PostReservationBodyFromJSONTyped(json, false);
}

export function PostReservationBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostReservationBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tourTravelersCount': PartialRecordAgeIdNumberFromJSON(json['tourTravelersCount']),
        'tourStartDate': json['tourStartDate'],
        'languageCode': LanguageCodeFromJSON(json['languageCode']),
        'tourId': json['tourId'],
        'optionId': json['optionId'],
        'time': json['time'],
        'utm': PostReservationBodyUtmFromJSON(json['utm']),
        'officeId': !exists(json, 'officeId') ? undefined : json['officeId'],
        'travelers': ((json['travelers'] as Array<any>).map(PostReservationBodyTravelerFromJSON)),
        'contact': !exists(json, 'contact') ? undefined : PostReservationBodyContactFromJSON(json['contact']),
        'extras': !exists(json, 'extras') ? undefined : ((json['extras'] as Array<any>).map(ExtraFromJSON)),
        'pickup': !exists(json, 'pickup') ? undefined : PickupFromJSON(json['pickup']),
        'einvoicing': !exists(json, 'einvoicing') ? undefined : EinvoicingFromJSON(json['einvoicing']),
        'discountId': !exists(json, 'discountId') ? undefined : json['discountId'],
        'sendEmail': !exists(json, 'sendEmail') ? undefined : json['sendEmail'],
        'splittedFromReservationId': !exists(json, 'splittedFromReservationId') ? undefined : json['splittedFromReservationId'],
        'bookAt': !exists(json, 'bookAt') ? undefined : FirebaseFirestoreTimestampFromJSON(json['bookAt']),
        'hour': !exists(json, 'hour') ? undefined : json['hour'],
    };
}

export function PostReservationBodyToJSON(value?: PostReservationBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tourTravelersCount': PartialRecordAgeIdNumberToJSON(value.tourTravelersCount),
        'tourStartDate': value.tourStartDate,
        'languageCode': LanguageCodeToJSON(value.languageCode),
        'tourId': value.tourId,
        'optionId': value.optionId,
        'time': value.time,
        'utm': PostReservationBodyUtmToJSON(value.utm),
        'officeId': value.officeId,
        'travelers': ((value.travelers as Array<any>).map(PostReservationBodyTravelerToJSON)),
        'contact': PostReservationBodyContactToJSON(value.contact),
        'extras': value.extras === undefined ? undefined : ((value.extras as Array<any>).map(ExtraToJSON)),
        'pickup': PickupToJSON(value.pickup),
        'einvoicing': EinvoicingToJSON(value.einvoicing),
        'discountId': value.discountId,
        'sendEmail': value.sendEmail,
        'splittedFromReservationId': value.splittedFromReservationId,
        'bookAt': FirebaseFirestoreTimestampToJSON(value.bookAt),
        'hour': value.hour,
    };
}

