/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdditionalInformation } from './AdditionalInformation';
import {
    AdditionalInformationFromJSON,
    AdditionalInformationFromJSONTyped,
    AdditionalInformationToJSON,
} from './AdditionalInformation';
import type { Included } from './Included';
import {
    IncludedFromJSON,
    IncludedFromJSONTyped,
    IncludedToJSON,
} from './Included';
import type { MeetingPointDescription } from './MeetingPointDescription';
import {
    MeetingPointDescriptionFromJSON,
    MeetingPointDescriptionFromJSONTyped,
    MeetingPointDescriptionToJSON,
} from './MeetingPointDescription';
import type { NotIncluded } from './NotIncluded';
import {
    NotIncludedFromJSON,
    NotIncludedFromJSONTyped,
    NotIncludedToJSON,
} from './NotIncluded';
import type { Photo } from './Photo';
import {
    PhotoFromJSON,
    PhotoFromJSONTyped,
    PhotoToJSON,
} from './Photo';
import type { ProductSetup } from './ProductSetup';
import {
    ProductSetupFromJSON,
    ProductSetupFromJSONTyped,
    ProductSetupToJSON,
} from './ProductSetup';
import type { TourDetails } from './TourDetails';
import {
    TourDetailsFromJSON,
    TourDetailsFromJSONTyped,
    TourDetailsToJSON,
} from './TourDetails';

/**
 * 
 * @export
 * @interface ProductContent
 */
export interface ProductContent {
    /**
     * 
     * @type {ProductSetup}
     * @memberof ProductContent
     */
    productSetup: ProductSetup;
    /**
     * 
     * @type {Array<Included>}
     * @memberof ProductContent
     */
    includeds?: Array<Included>;
    /**
     * 
     * @type {Array<NotIncluded>}
     * @memberof ProductContent
     */
    notIncludeds?: Array<NotIncluded>;
    /**
     * 
     * @type {Array<AdditionalInformation>}
     * @memberof ProductContent
     */
    additionalInformations?: Array<AdditionalInformation>;
    /**
     * 
     * @type {TourDetails}
     * @memberof ProductContent
     */
    tourDetails?: TourDetails;
    /**
     * 
     * @type {MeetingPointDescription}
     * @memberof ProductContent
     */
    cancelPolicy?: MeetingPointDescription | null;
    /**
     * 
     * @type {Array<Photo>}
     * @memberof ProductContent
     */
    photos?: Array<Photo>;
}

/**
 * Check if a given object implements the ProductContent interface.
 */
export function instanceOfProductContent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "productSetup" in value;

    return isInstance;
}

export function ProductContentFromJSON(json: any): ProductContent {
    return ProductContentFromJSONTyped(json, false);
}

export function ProductContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'productSetup': ProductSetupFromJSON(json['productSetup']),
        'includeds': !exists(json, 'includeds') ? undefined : ((json['includeds'] as Array<any>).map(IncludedFromJSON)),
        'notIncludeds': !exists(json, 'notIncludeds') ? undefined : ((json['notIncludeds'] as Array<any>).map(NotIncludedFromJSON)),
        'additionalInformations': !exists(json, 'additionalInformations') ? undefined : ((json['additionalInformations'] as Array<any>).map(AdditionalInformationFromJSON)),
        'tourDetails': !exists(json, 'tourDetails') ? undefined : TourDetailsFromJSON(json['tourDetails']),
        'cancelPolicy': !exists(json, 'cancelPolicy') ? undefined : MeetingPointDescriptionFromJSON(json['cancelPolicy']),
        'photos': !exists(json, 'photos') ? undefined : ((json['photos'] as Array<any>).map(PhotoFromJSON)),
    };
}

export function ProductContentToJSON(value?: ProductContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'productSetup': ProductSetupToJSON(value.productSetup),
        'includeds': value.includeds === undefined ? undefined : ((value.includeds as Array<any>).map(IncludedToJSON)),
        'notIncludeds': value.notIncludeds === undefined ? undefined : ((value.notIncludeds as Array<any>).map(NotIncludedToJSON)),
        'additionalInformations': value.additionalInformations === undefined ? undefined : ((value.additionalInformations as Array<any>).map(AdditionalInformationToJSON)),
        'tourDetails': TourDetailsToJSON(value.tourDetails),
        'cancelPolicy': MeetingPointDescriptionToJSON(value.cancelPolicy),
        'photos': value.photos === undefined ? undefined : ((value.photos as Array<any>).map(PhotoToJSON)),
    };
}

