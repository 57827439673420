import React from "react";
import { notification as notificationAntd } from "antd";
import { NotificationPlacement } from "antd/lib/notification";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import common from "../styles/themes/common.json";

type Type = "error" | "success" | "info" | "warning" | "close";

interface NotificationProps {
  type: Type;
  title?: string;
  description?: string | React.ReactNode;
  placement?: NotificationPlacement;
  duration?: number;
  key?: string;
  icon?: React.ReactNode;
}

interface StatusType {
  title: string;
  description: string;
  color: string;
}

export const notification = ({
  type,
  title,
  description,
  placement = "bottomRight",
  duration = 5,
  key,
  icon,
}: NotificationProps): void => {
  if (type === "close") return key ? notificationAntd.close(key) : undefined;

  const currentType = types(type);

  return notificationAntd[type]({
    key: key,
    duration: duration,
    placement: placement,
    message: title || currentType.title,
    className: "notification-custom",
    closeIcon: <FontAwesomeIcon icon={faTimes} size="2x" color="#20415A" />,
    icon: icon,
    description: description || currentType.description,
    style: {
      borderLeft: `10px solid ${currentType.color}`,
    },
  });
};

const types = (type: Omit<Type, "close">): StatusType => {
  const status = {
    error: {
      title: "An error occurred!",
      description: "Please try again later...",
      color: `${common.colors.error}`,
    },
    success: {
      title: "Successful operation!",
      description: "",
      color: `${common.colors.success}`,
    },
    info: {
      title: "Important information!",
      description: "Please take a moment to review this information",
      color: `${common.colors.info}`,
    },
    warning: {
      title: "Problematic situation detected!",
      description:
        "We recommend reviewing the details before performing any operation",
      color: `${common.colors.warning}`,
    },
  };

  // @ts-ignore
  return status[type];
};
