import React, { createContext, useContext, useEffect } from "react";
import { useCollectionData } from "../hooks/firebase";
import { useAuthentication } from "./AuthenticationProvider";
import { notification, Spinner } from "../components";
import { orderBy } from "lodash";
import { where } from "firebase/firestore";

interface Context {
  operators: Operator[];
  countries: Country[];
  cities: City[];
  agencies: Agency[];
  offices: Office[];
}

const GlobalDataContext = createContext<Context>({
  operators: [],
  countries: [],
  cities: [],
  agencies: [],
  offices: [],
});

interface Props {
  children: JSX.Element;
}

export const GlobalDataProvider = ({ children }: Props): JSX.Element => {
  const { authUser } = useAuthentication();

  const [
    operators = [],
    operatorsLoading,
    operatorsError,
  ] = useCollectionData<Operator>(
    "onSnapshot",
    "operators",
    [],
    [authUser?.id],
    !authUser?.id
  );

  const [
    countries = [],
    countriesLoading,
    countriesError,
  ] = useCollectionData<Country>(
    "onSnapshot",
    "countries",
    [where("isDeleted", "==", false)],
    [authUser?.id],
    !authUser?.id
  );

  const [cities = [], citiesLoading, citiesError] = useCollectionData<City>(
    "onSnapshot",
    "cities",
    [where("isDeleted", "==", false)],
    [authUser?.id],
    !authUser?.id
  );

  const [
    agencies = [],
    agenciesLoading,
    agenciesError,
  ] = useCollectionData<Agency>(
    "onSnapshot",
    "agencies",
    [where("isDeleted", "==", false)],
    [authUser?.id],
    !authUser?.id
  );

  const [
    offices = [],
    officesLoading,
    officesError,
  ] = useCollectionData<Office>(
    "onSnapshot",
    "offices",
    [where("isDeleted", "==", false)],
    [authUser?.id],
    !authUser?.id
  );

  const error =
    operatorsError ||
    countriesError ||
    citiesError ||
    agenciesError ||
    officesError;

  const loading =
    operatorsLoading ||
    countriesLoading ||
    citiesLoading ||
    agenciesLoading ||
    officesLoading;

  useEffect(() => {
    error && console.error("no se pudo cargar los datos globales");
    error && notification({ type: "error" });
  }, [error]);

  if (loading && location.pathname !== "/") return <Spinner fullscreen />;

  return (
    <GlobalDataContext.Provider
      value={{
        operators: orderBy(operators, (operator) => operator.name, "asc"),
        countries: orderBy(countries, (country) => country.name, "asc"),
        cities: orderBy(cities, (city) => city.name, "asc"),
        agencies: orderBy(agencies, (agency) => agency.name, "asc"),
        offices: orderBy(offices, (office) => office.name, "asc"),
      }}
    >
      {children}
    </GlobalDataContext.Provider>
  );
};

export const useGlobalData = (): Context => useContext(GlobalDataContext);
