import { lazy } from "react";

const Page404 = lazy(() => import("./404"));
const Page403 = lazy(() => import("./403"));
const HomeIntegration = lazy(() => import("./home"));
const SearchIntegration = lazy(() => import("./search"));
const ActivitiesIntegration = lazy(() => import("./a/_star"));
const TourIntegration = lazy(() => import("./tours/tourId"));
const TravelersIntegration = lazy(
  () => import("./tours/tourId/booking/travelers")
);
const ExtrasIntegration = lazy(() => import("./tours/tourId/booking/extras"));
const PaymentIntegration = lazy(() => import("./tours/tourId/booking/payment"));
const PaymentSuccessIntegration = lazy(
  () => import("./tours/tourId/booking/payment/success")
);
const PaymentErrorIntegration = lazy(
  () => import("./tours/tourId/booking/payment/error")
);
const SetPickupLocationIntegration = lazy(
  () => import("./set-pickup-location")
);
const UploadTravelersPassportsIntegration = lazy(
  () => import("./upload-travelers-passports")
);
const TermsAndConditionsIntegration = lazy(
  () => import("./terms-and-conditions")
);
const ChangeAndCancellationPoliciesIntegration = lazy(
  () => import("./change-and-cancellation-policies")
);
const HelpCenterIntegration = lazy(() => import("./help-center"));

const AboutUsIntegration = lazy(() => import("./about-us"));
const SeoIntegration = lazy(() => import("./t/_star"));
const LoginIntegration = lazy(() => import("./login"));
const ResetPasswordIntegration = lazy(() => import("./reset-password"));
const AccountIntegration = lazy(() => import("./account"));
const EditProfileIntegration = lazy(() => import("./account/edit"));

export {
  Page404,
  Page403,
  HomeIntegration,
  SearchIntegration,
  ActivitiesIntegration,
  TourIntegration,
  TravelersIntegration,
  ExtrasIntegration,
  PaymentIntegration,
  PaymentSuccessIntegration,
  PaymentErrorIntegration,
  SetPickupLocationIntegration,
  TermsAndConditionsIntegration,
  ChangeAndCancellationPoliciesIntegration,
  SeoIntegration,
  UploadTravelersPassportsIntegration,
  HelpCenterIntegration,
  AboutUsIntegration,
  LoginIntegration,
  ResetPasswordIntegration,
  AccountIntegration,
  EditProfileIntegration,
};
