/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CurrencyAnyOf } from './CurrencyAnyOf';
import {
    CurrencyAnyOfFromJSON,
    CurrencyAnyOfFromJSONTyped,
    CurrencyAnyOfToJSON,
} from './CurrencyAnyOf';
import type { TransactionCurrency } from './TransactionCurrency';
import {
    TransactionCurrencyFromJSON,
    TransactionCurrencyFromJSONTyped,
    TransactionCurrencyToJSON,
} from './TransactionCurrency';

/**
 * 
 * @export
 * @interface Currency
 */
export interface Currency {
}

/**
 * Check if a given object implements the Currency interface.
 */
export function instanceOfCurrency(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CurrencyFromJSON(json: any): Currency {
    return CurrencyFromJSONTyped(json, false);
}

export function CurrencyFromJSONTyped(json: any, ignoreDiscriminator: boolean): Currency {
    return json;
}

export function CurrencyToJSON(value?: Currency | null): any {
    return value;
}

