/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostSetDropOffBody
 */
export interface PostSetDropOffBody {
    /**
     * 
     * @type {string}
     * @memberof PostSetDropOffBody
     */
    dropOff: string;
    /**
     * 
     * @type {boolean}
     * @memberof PostSetDropOffBody
     */
    sendEmail: boolean;
}

/**
 * Check if a given object implements the PostSetDropOffBody interface.
 */
export function instanceOfPostSetDropOffBody(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "dropOff" in value;
    isInstance = isInstance && "sendEmail" in value;

    return isInstance;
}

export function PostSetDropOffBodyFromJSON(json: any): PostSetDropOffBody {
    return PostSetDropOffBodyFromJSONTyped(json, false);
}

export function PostSetDropOffBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostSetDropOffBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dropOff': json['dropOff'],
        'sendEmail': json['sendEmail'],
    };
}

export function PostSetDropOffBodyToJSON(value?: PostSetDropOffBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dropOff': value.dropOff,
        'sendEmail': value.sendEmail,
    };
}

