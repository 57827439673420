interface Props {
  "4k": number;
  high_resolution: number;
  desktop_large: number;
  desktop: number;
  tablet: number;
  mobile: number;
  mobile_s: number;
}

export const breakPoints: Props = {
  "4k": 2560,
  high_resolution: 1600,
  desktop_large: 1200,
  desktop: 992,
  tablet: 768,
  mobile: 576,
  mobile_s: 320,
};
