/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReservationsSummary
 */
export interface ReservationsSummary {
    /**
     * 
     * @type {string}
     * @memberof ReservationsSummary
     */
    tourStartDate: string;
    /**
     * 
     * @type {string}
     * @memberof ReservationsSummary
     */
    tourName: string;
    /**
     * 
     * @type {string}
     * @memberof ReservationsSummary
     */
    optionName: string;
    /**
     * 
     * @type {string}
     * @memberof ReservationsSummary
     */
    optionTime: string;
    /**
     * 
     * @type {number}
     * @memberof ReservationsSummary
     */
    totalTravelers: number;
    /**
     * 
     * @type {string}
     * @memberof ReservationsSummary
     */
    totalLiquidationPrice: string;
    /**
     * 
     * @type {number}
     * @memberof ReservationsSummary
     */
    totalNoShows: number;
    /**
     * 
     * @type {number}
     * @memberof ReservationsSummary
     */
    totalReservations: number;
}

/**
 * Check if a given object implements the ReservationsSummary interface.
 */
export function instanceOfReservationsSummary(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "tourStartDate" in value;
    isInstance = isInstance && "tourName" in value;
    isInstance = isInstance && "optionName" in value;
    isInstance = isInstance && "optionTime" in value;
    isInstance = isInstance && "totalTravelers" in value;
    isInstance = isInstance && "totalLiquidationPrice" in value;
    isInstance = isInstance && "totalNoShows" in value;
    isInstance = isInstance && "totalReservations" in value;

    return isInstance;
}

export function ReservationsSummaryFromJSON(json: any): ReservationsSummary {
    return ReservationsSummaryFromJSONTyped(json, false);
}

export function ReservationsSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReservationsSummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tourStartDate': json['tourStartDate'],
        'tourName': json['tourName'],
        'optionName': json['optionName'],
        'optionTime': json['optionTime'],
        'totalTravelers': json['totalTravelers'],
        'totalLiquidationPrice': json['totalLiquidationPrice'],
        'totalNoShows': json['totalNoShows'],
        'totalReservations': json['totalReservations'],
    };
}

export function ReservationsSummaryToJSON(value?: ReservationsSummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tourStartDate': value.tourStartDate,
        'tourName': value.tourName,
        'optionName': value.optionName,
        'optionTime': value.optionTime,
        'totalTravelers': value.totalTravelers,
        'totalLiquidationPrice': value.totalLiquidationPrice,
        'totalNoShows': value.totalNoShows,
        'totalReservations': value.totalReservations,
    };
}

