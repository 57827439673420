/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FirebaseFirestoreTimestamp } from './FirebaseFirestoreTimestamp';
import {
    FirebaseFirestoreTimestampFromJSON,
    FirebaseFirestoreTimestampFromJSONTyped,
    FirebaseFirestoreTimestampToJSON,
} from './FirebaseFirestoreTimestamp';

/**
 * 
 * @export
 * @interface TravelerPassportImage
 */
export interface TravelerPassportImage {
    /**
     * 
     * @type {FirebaseFirestoreTimestamp}
     * @memberof TravelerPassportImage
     */
    createAt: FirebaseFirestoreTimestamp;
    /**
     * 
     * @type {string}
     * @memberof TravelerPassportImage
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof TravelerPassportImage
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof TravelerPassportImage
     */
    uid: string;
}

/**
 * Check if a given object implements the TravelerPassportImage interface.
 */
export function instanceOfTravelerPassportImage(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "createAt" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "url" in value;
    isInstance = isInstance && "uid" in value;

    return isInstance;
}

export function TravelerPassportImageFromJSON(json: any): TravelerPassportImage {
    return TravelerPassportImageFromJSONTyped(json, false);
}

export function TravelerPassportImageFromJSONTyped(json: any, ignoreDiscriminator: boolean): TravelerPassportImage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createAt': FirebaseFirestoreTimestampFromJSON(json['createAt']),
        'name': json['name'],
        'url': json['url'],
        'uid': json['uid'],
    };
}

export function TravelerPassportImageToJSON(value?: TravelerPassportImage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createAt': FirebaseFirestoreTimestampToJSON(value.createAt),
        'name': value.name,
        'url': value.url,
        'uid': value.uid,
    };
}

