/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MeetingPointDescription
 */
export interface MeetingPointDescription {
    /**
     * 
     * @type {string}
     * @memberof MeetingPointDescription
     */
    en: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingPointDescription
     */
    es: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingPointDescription
     */
    pt: string;
}

/**
 * Check if a given object implements the MeetingPointDescription interface.
 */
export function instanceOfMeetingPointDescription(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "en" in value;
    isInstance = isInstance && "es" in value;
    isInstance = isInstance && "pt" in value;

    return isInstance;
}

export function MeetingPointDescriptionFromJSON(json: any): MeetingPointDescription {
    return MeetingPointDescriptionFromJSONTyped(json, false);
}

export function MeetingPointDescriptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): MeetingPointDescription {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'en': json['en'],
        'es': json['es'],
        'pt': json['pt'],
    };
}

export function MeetingPointDescriptionToJSON(value?: MeetingPointDescription | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'en': value.en,
        'es': value.es,
        'pt': value.pt,
    };
}

