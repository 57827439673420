import React from "react";
import styled, { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/pro-solid-svg-icons";
import { SizeProp } from "@fortawesome/fontawesome-svg-core";

interface ContainerProps {
  fullscreen: boolean;
  height?: string;
}

interface Props extends Partial<ContainerProps> {
  size?: SizeProp;
}

export const Spinner = ({
  height,
  fullscreen = true,
  size = "6x",
}: Props): JSX.Element => (
  <Container fullscreen={fullscreen} height={height}>
    <IconStyled spinPulse icon={faSpinner} size={size} />
  </Container>
);

const Container = styled.section<ContainerProps>`
  ${({ fullscreen, height }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: ${height || (fullscreen ? "100%" : " calc(100% - 90px)")};
    opacity: 90%;
  `}
`;

const IconStyled = styled(FontAwesomeIcon)`
  ${({ theme }) => css`
    color: ${theme.colors.primary};
  `}
`;
