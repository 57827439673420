/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostRefundBody
 */
export interface PostRefundBody {
    /**
     * 
     * @type {string}
     * @memberof PostRefundBody
     */
    paymentId: string;
    /**
     * 
     * @type {string}
     * @memberof PostRefundBody
     */
    amount: string;
    /**
     * 
     * @type {string}
     * @memberof PostRefundBody
     */
    refundReason: string;
    /**
     * 
     * @type {boolean}
     * @memberof PostRefundBody
     */
    sendEmail: boolean;
}

/**
 * Check if a given object implements the PostRefundBody interface.
 */
export function instanceOfPostRefundBody(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "paymentId" in value;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "refundReason" in value;
    isInstance = isInstance && "sendEmail" in value;

    return isInstance;
}

export function PostRefundBodyFromJSON(json: any): PostRefundBody {
    return PostRefundBodyFromJSONTyped(json, false);
}

export function PostRefundBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostRefundBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'paymentId': json['paymentId'],
        'amount': json['amount'],
        'refundReason': json['refundReason'],
        'sendEmail': json['sendEmail'],
    };
}

export function PostRefundBodyToJSON(value?: PostRefundBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'paymentId': value.paymentId,
        'amount': value.amount,
        'refundReason': value.refundReason,
        'sendEmail': value.sendEmail,
    };
}

