/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const LanguageCode = {
    En: 'en',
    Es: 'es',
    Pt: 'pt'
} as const;
export type LanguageCode = typeof LanguageCode[keyof typeof LanguageCode];


export function LanguageCodeFromJSON(json: any): LanguageCode {
    return LanguageCodeFromJSONTyped(json, false);
}

export function LanguageCodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): LanguageCode {
    return json as LanguageCode;
}

export function LanguageCodeToJSON(value?: LanguageCode | null): any {
    return value as any;
}

