/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TravelersFields
 */
export interface TravelersFields {
    /**
     * 
     * @type {boolean}
     * @memberof TravelersFields
     */
    fullName: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TravelersFields
     */
    age: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TravelersFields
     */
    gender: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TravelersFields
     */
    country: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TravelersFields
     */
    weight: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TravelersFields
     */
    height: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TravelersFields
     */
    dateOfBirth: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TravelersFields
     */
    passport: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TravelersFields
     */
    flightNumber: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TravelersFields
     */
    arrivalTime: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TravelersFields
     */
    airline: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TravelersFields
     */
    food: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TravelersFields
     */
    dietaryRestrictions: boolean;
}

/**
 * Check if a given object implements the TravelersFields interface.
 */
export function instanceOfTravelersFields(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "fullName" in value;
    isInstance = isInstance && "age" in value;
    isInstance = isInstance && "gender" in value;
    isInstance = isInstance && "country" in value;
    isInstance = isInstance && "weight" in value;
    isInstance = isInstance && "height" in value;
    isInstance = isInstance && "dateOfBirth" in value;
    isInstance = isInstance && "passport" in value;
    isInstance = isInstance && "flightNumber" in value;
    isInstance = isInstance && "arrivalTime" in value;
    isInstance = isInstance && "airline" in value;
    isInstance = isInstance && "food" in value;
    isInstance = isInstance && "dietaryRestrictions" in value;

    return isInstance;
}

export function TravelersFieldsFromJSON(json: any): TravelersFields {
    return TravelersFieldsFromJSONTyped(json, false);
}

export function TravelersFieldsFromJSONTyped(json: any, ignoreDiscriminator: boolean): TravelersFields {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fullName': json['fullName'],
        'age': json['age'],
        'gender': json['gender'],
        'country': json['country'],
        'weight': json['weight'],
        'height': json['height'],
        'dateOfBirth': json['dateOfBirth'],
        'passport': json['passport'],
        'flightNumber': json['flightNumber'],
        'arrivalTime': json['arrivalTime'],
        'airline': json['airline'],
        'food': json['food'],
        'dietaryRestrictions': json['dietaryRestrictions'],
    };
}

export function TravelersFieldsToJSON(value?: TravelersFields | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fullName': value.fullName,
        'age': value.age,
        'gender': value.gender,
        'country': value.country,
        'weight': value.weight,
        'height': value.height,
        'dateOfBirth': value.dateOfBirth,
        'passport': value.passport,
        'flightNumber': value.flightNumber,
        'arrivalTime': value.arrivalTime,
        'airline': value.airline,
        'food': value.food,
        'dietaryRestrictions': value.dietaryRestrictions,
    };
}

