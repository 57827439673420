/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Einvoicing
 */
export interface Einvoicing {
    /**
     * 
     * @type {string}
     * @memberof Einvoicing
     */
    type: EinvoicingTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Einvoicing
     */
    ruc?: string;
    /**
     * 
     * @type {string}
     * @memberof Einvoicing
     */
    socialReason?: string;
    /**
     * 
     * @type {string}
     * @memberof Einvoicing
     */
    receiptNumber?: string;
}


/**
 * @export
 */
export const EinvoicingTypeEnum = {
    Boleta: 'boleta',
    Factura: 'factura'
} as const;
export type EinvoicingTypeEnum = typeof EinvoicingTypeEnum[keyof typeof EinvoicingTypeEnum];


/**
 * Check if a given object implements the Einvoicing interface.
 */
export function instanceOfEinvoicing(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function EinvoicingFromJSON(json: any): Einvoicing {
    return EinvoicingFromJSONTyped(json, false);
}

export function EinvoicingFromJSONTyped(json: any, ignoreDiscriminator: boolean): Einvoicing {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'ruc': !exists(json, 'ruc') ? undefined : json['ruc'],
        'socialReason': !exists(json, 'socialReason') ? undefined : json['socialReason'],
        'receiptNumber': !exists(json, 'receiptNumber') ? undefined : json['receiptNumber'],
    };
}

export function EinvoicingToJSON(value?: Einvoicing | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'ruc': value.ruc,
        'socialReason': value.socialReason,
        'receiptNumber': value.receiptNumber,
    };
}

