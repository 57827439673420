/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OpenpayTokenCardAddress
 */
export interface OpenpayTokenCardAddress {
    /**
     * 
     * @type {string}
     * @memberof OpenpayTokenCardAddress
     */
    countryCode: string;
    /**
     * 
     * @type {string}
     * @memberof OpenpayTokenCardAddress
     */
    postalCode: string;
    /**
     * 
     * @type {string}
     * @memberof OpenpayTokenCardAddress
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof OpenpayTokenCardAddress
     */
    state: string;
    /**
     * 
     * @type {string}
     * @memberof OpenpayTokenCardAddress
     */
    line3: string;
    /**
     * 
     * @type {string}
     * @memberof OpenpayTokenCardAddress
     */
    line2: string;
    /**
     * 
     * @type {string}
     * @memberof OpenpayTokenCardAddress
     */
    line1: string;
}

/**
 * Check if a given object implements the OpenpayTokenCardAddress interface.
 */
export function instanceOfOpenpayTokenCardAddress(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "countryCode" in value;
    isInstance = isInstance && "postalCode" in value;
    isInstance = isInstance && "city" in value;
    isInstance = isInstance && "state" in value;
    isInstance = isInstance && "line3" in value;
    isInstance = isInstance && "line2" in value;
    isInstance = isInstance && "line1" in value;

    return isInstance;
}

export function OpenpayTokenCardAddressFromJSON(json: any): OpenpayTokenCardAddress {
    return OpenpayTokenCardAddressFromJSONTyped(json, false);
}

export function OpenpayTokenCardAddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): OpenpayTokenCardAddress {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'countryCode': json['country_code'],
        'postalCode': json['postal_code'],
        'city': json['city'],
        'state': json['state'],
        'line3': json['line3'],
        'line2': json['line2'],
        'line1': json['line1'],
    };
}

export function OpenpayTokenCardAddressToJSON(value?: OpenpayTokenCardAddress | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'country_code': value.countryCode,
        'postal_code': value.postalCode,
        'city': value.city,
        'state': value.state,
        'line3': value.line3,
        'line2': value.line2,
        'line1': value.line1,
    };
}

