import { DocumentData, FirestoreDataConverter } from "firebase/firestore";

export const firebaseDataConverter = <
  T extends DocumentData
>(): FirestoreDataConverter<T> => ({
  toFirestore(post: T): DocumentData {
    return { ...post };
  },

  fromFirestore(docSnap): T {
    return docSnap.data() as T;
  },
});
