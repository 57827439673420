import React, { memo } from "react";
import { Router } from "./router";
import {
  AuthenticationProvider,
  GlobalDataProvider,
  GlobalOperatorDataProvider,
  OfficeProvider,
  OperatorProvider,
  UTMProvider,
  VersionProvider,
  CardLinkPaymentNotificationProvider,
  SearchResultsProvider,
  ReservationProvider,
} from "./providers";

const App = () => {
  return (
    <VersionProvider>
      <AuthenticationProvider>
        <SearchResultsProvider>
          <GlobalDataProvider>
            <OperatorProvider>
              <GlobalOperatorDataProvider>
                <CardLinkPaymentNotificationProvider>
                  <OfficeProvider>
                    <UTMProvider>
                      <ReservationProvider>
                        <Router />
                      </ReservationProvider>
                    </UTMProvider>
                  </OfficeProvider>
                </CardLinkPaymentNotificationProvider>
              </GlobalOperatorDataProvider>
            </OperatorProvider>
          </GlobalDataProvider>
        </SearchResultsProvider>
      </AuthenticationProvider>
    </VersionProvider>
  );
};

export default memo(App);
