/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const Theme = {
    Findalocaltour: 'findalocaltour',
    Airportexpresslima: 'airportexpresslima',
    Huacapucllanamiraflores: 'huacapucllanamiraflores',
    Inkaexpress: 'inkaexpress',
    Luchitoscookingclass: 'luchitoscookingclass',
    Rainbowmountaintravels: 'rainbowmountaintravels',
    Yapaexplorers: 'yapaexplorers',
    Findlocaltrips: 'findlocaltrips',
    Escapefromlima: 'escapefromlima'
} as const;
export type Theme = typeof Theme[keyof typeof Theme];


export function ThemeFromJSON(json: any): Theme {
    return ThemeFromJSONTyped(json, false);
}

export function ThemeFromJSONTyped(json: any, ignoreDiscriminator: boolean): Theme {
    return json as Theme;
}

export function ThemeToJSON(value?: Theme | null): any {
    return value as any;
}

