/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AgeTiers } from './AgeTiers';
import {
    AgeTiersFromJSON,
    AgeTiersFromJSONTyped,
    AgeTiersToJSON,
} from './AgeTiers';
import type { Day } from './Day';
import {
    DayFromJSON,
    DayFromJSONTyped,
    DayToJSON,
} from './Day';

/**
 * 
 * @export
 * @interface Pricing
 */
export interface Pricing {
    /**
     * 
     * @type {string}
     * @memberof Pricing
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof Pricing
     */
    enabledTieredPricing: boolean;
    /**
     * 
     * @type {Array<AgeTiers>}
     * @memberof Pricing
     */
    agesTiers: Array<AgeTiers>;
    /**
     * 
     * @type {Array<Day>}
     * @memberof Pricing
     */
    days: Array<Day>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Pricing
     */
    times: Array<string>;
}

/**
 * Check if a given object implements the Pricing interface.
 */
export function instanceOfPricing(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "enabledTieredPricing" in value;
    isInstance = isInstance && "agesTiers" in value;
    isInstance = isInstance && "days" in value;
    isInstance = isInstance && "times" in value;

    return isInstance;
}

export function PricingFromJSON(json: any): Pricing {
    return PricingFromJSONTyped(json, false);
}

export function PricingFromJSONTyped(json: any, ignoreDiscriminator: boolean): Pricing {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'enabledTieredPricing': json['enabledTieredPricing'],
        'agesTiers': ((json['agesTiers'] as Array<any>).map(AgeTiersFromJSON)),
        'days': ((json['days'] as Array<any>).map(DayFromJSON)),
        'times': json['times'],
    };
}

export function PricingToJSON(value?: Pricing | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'enabledTieredPricing': value.enabledTieredPricing,
        'agesTiers': ((value.agesTiers as Array<any>).map(AgeTiersToJSON)),
        'days': ((value.days as Array<any>).map(DayToJSON)),
        'times': value.times,
    };
}

