/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Image
 */
export interface Image {
    /**
     * 
     * @type {string}
     * @memberof Image
     */
    uid: string;
    /**
     * 
     * @type {string}
     * @memberof Image
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof Image
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Image
     */
    thumbUrl: string;
}

/**
 * Check if a given object implements the Image interface.
 */
export function instanceOfImage(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "uid" in value;
    isInstance = isInstance && "url" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "thumbUrl" in value;

    return isInstance;
}

export function ImageFromJSON(json: any): Image {
    return ImageFromJSONTyped(json, false);
}

export function ImageFromJSONTyped(json: any, ignoreDiscriminator: boolean): Image {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'uid': json['uid'],
        'url': json['url'],
        'name': json['name'],
        'thumbUrl': json['thumbUrl'],
    };
}

export function ImageToJSON(value?: Image | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'uid': value.uid,
        'url': value.url,
        'name': value.name,
        'thumbUrl': value.thumbUrl,
    };
}

