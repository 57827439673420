import {
  DocumentData,
  Query,
  DocumentReference,
  QuerySnapshot,
  getDocs,
  getDoc,
  DocumentSnapshot,
  setDoc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";

type Document<T extends DocumentData> = { id: string } & T;

export const querySnapshotToArray = <T extends DocumentData>(
  querySnapshot: QuerySnapshot
): Document<T>[] => {
  const documents: Document<T>[] = [];

  querySnapshot.forEach((documentSnapshot) => {
    const document = documentSnapshot.data() as T;
    documents.push({ ...document, id: documentSnapshot.id });
  });

  return documents;
};

export const fetchCollectionOnce = async <T extends DocumentData>(
  query: Query
): Promise<Document<T>[]> => {
  const querySnapshot = await getDocs(query);

  return querySnapshotToArray<T>(querySnapshot);
};

export const fetchDocumentOnce = async <T extends DocumentData>(
  documentReference: DocumentReference
): Promise<T | undefined> => {
  const documentSnapshot = (await getDoc(
    documentReference
  )) as DocumentSnapshot<T>;

  return documentSnapshot.data();
};

export const setDocument = async <T extends ObjectType>(
  docRef: DocumentReference,
  document: T
): Promise<void> => setDoc(docRef, document);

export const updateDocument = async <T extends ObjectType>(
  docRef: DocumentReference,
  document: T
): Promise<void> => updateDoc(docRef, document);

export const mergeDocument = async <T extends ObjectType>(
  docRef: DocumentReference,
  document: T
): Promise<void> => setDoc(docRef, document, { merge: true });

export const deleteDocument = async (
  docRef: DocumentReference
): Promise<void> => deleteDoc(docRef);
