/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AgePrice } from './AgePrice';
import {
    AgePriceFromJSON,
    AgePriceFromJSONTyped,
    AgePriceToJSON,
} from './AgePrice';

/**
 * 
 * @export
 * @interface TimeAvailabilityPricing
 */
export interface TimeAvailabilityPricing {
    /**
     * 
     * @type {string}
     * @memberof TimeAvailabilityPricing
     */
    time: string;
    /**
     * 
     * @type {number}
     * @memberof TimeAvailabilityPricing
     */
    availability: number;
    /**
     * 
     * @type {Array<AgePrice>}
     * @memberof TimeAvailabilityPricing
     */
    agePrices: Array<AgePrice>;
    /**
     * 
     * @type {string}
     * @memberof TimeAvailabilityPricing
     */
    totalSubAmount: string;
    /**
     * 
     * @type {string}
     * @memberof TimeAvailabilityPricing
     */
    travelersPerOptionId?: string;
}

/**
 * Check if a given object implements the TimeAvailabilityPricing interface.
 */
export function instanceOfTimeAvailabilityPricing(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "time" in value;
    isInstance = isInstance && "availability" in value;
    isInstance = isInstance && "agePrices" in value;
    isInstance = isInstance && "totalSubAmount" in value;

    return isInstance;
}

export function TimeAvailabilityPricingFromJSON(json: any): TimeAvailabilityPricing {
    return TimeAvailabilityPricingFromJSONTyped(json, false);
}

export function TimeAvailabilityPricingFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimeAvailabilityPricing {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'time': json['time'],
        'availability': json['availability'],
        'agePrices': ((json['agePrices'] as Array<any>).map(AgePriceFromJSON)),
        'totalSubAmount': json['totalSubAmount'],
        'travelersPerOptionId': !exists(json, 'travelersPerOptionId') ? undefined : json['travelersPerOptionId'],
    };
}

export function TimeAvailabilityPricingToJSON(value?: TimeAvailabilityPricing | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'time': value.time,
        'availability': value.availability,
        'agePrices': ((value.agePrices as Array<any>).map(AgePriceToJSON)),
        'totalSubAmount': value.totalSubAmount,
        'travelersPerOptionId': value.travelersPerOptionId,
    };
}

