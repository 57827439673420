import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  GetReservation,
  GetReservationRequest,
  ReservationsApi,
} from "../openapi";
import { useI18n, useQuery } from "../hooks";
import { useOpenApi } from "../api";
import { pathnameWithLanguage } from "../utils";
import { useLanguage } from "../providers";
import { ModalAlert } from "../components";

interface Context {
  reservation?: GetReservation["reservation"];
  reservationError?: string;
  reservationLoading: boolean;
  getReservation: (params: GetReservationRequest) => Promise<unknown>;
}

const ReservationContext = createContext<Context>({
  reservation: undefined,
  reservationError: undefined,
  reservationLoading: false,
  getReservation: async ({ reservationId }) => {
    return console.log("Get reservation", reservationId);
  },
});

interface Query {
  reservationId: string;
}

interface Props {
  children: JSX.Element;
}

export const ReservationProvider = ({ children }: Props): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const translate = useI18n();

  const { reservationId } = useQuery<Query>();
  const { currentLanguageCode } = useLanguage();

  const {
    data,
    setData,
    run: getReservation,
    loading: reservationLoading,
    error: reservationError,
  } = useOpenApi(ReservationsApi, "getReservation", {
    initialParams: reservationId ? [{ reservationId }] : undefined,
    deps: [location.pathname],
  });

  useEffect(() => {
    if (reservationId) return;

    setData(undefined);
  }, [location.pathname]);

  const onContinueToHomePage = () =>
    navigate(pathnameWithLanguage(currentLanguageCode, "/"));

  return (
    <ReservationContext.Provider
      value={{
        reservation: data?.reservation,
        reservationError,
        reservationLoading,
        getReservation,
      }}
    >
      {reservationId && data && (
        <SaleSessionExpired
          data={data}
          translate={translate}
          onContinue={onContinueToHomePage}
        />
      )}
      {reservationId && data && (
        <PaymentAlreadyProcessed
          data={data}
          translate={translate}
          onContinue={onContinueToHomePage}
        />
      )}
      {children}
    </ReservationContext.Provider>
  );
};

export const useReservation = (): Context => useContext(ReservationContext);

interface SaleSessionExpiredProps {
  translate: Translate;
  data: GetReservation;
  onContinue: () => void;
}

const SaleSessionExpired = ({
  translate,
  onContinue,
  data,
}: SaleSessionExpiredProps): JSX.Element => {
  const [isSaleExpired, setIsSaleExpired] = useState<boolean>(false);

  useEffect(() => {
    validateSaleSession();
  }, [data.hasExpired]);

  const validateSaleSession = () => {
    if (location.pathname.match("/payment/.+")) return;

    setIsSaleExpired(data.hasExpired);
  };

  return (
    <ModalAlert
      variant="warning"
      visible={isSaleExpired}
      zIndex={1000001}
      title={translate("notifications.sale_session_expired.title")}
      description={translate("notifications.sale_session_expired.description")}
      okButtonProps={{
        onClick: onContinue,
        text: translate("general.continue"),
      }}
    />
  );
};

interface PaymentAlreadyProcessedProps {
  translate: Translate;
  data: GetReservation;
  onContinue: () => void;
}

const PaymentAlreadyProcessed = ({
  translate,
  onContinue,
  data,
}: PaymentAlreadyProcessedProps): JSX.Element => {
  const [
    isPaymentAlreadyProcessed,
    setIsPaymentAlreadyProcessed,
  ] = useState<boolean>(false);

  useEffect(() => {
    validatePaymentProcessed();
  }, [data.reservation.status, location.pathname]);

  const validatePaymentProcessed = () => {
    if (
      location.pathname.match("/booking/.+") &&
      !location.pathname.match("/payment/.+")
    ) {
      setIsPaymentAlreadyProcessed(data.reservation.status === "confirmed");
    }
  };

  return (
    <ModalAlert
      variant="info"
      zIndex={1000002}
      visible={isPaymentAlreadyProcessed}
      title={translate("general.payment_already_processed")}
      description={translate("general.payment_already_processed_detail")}
      okButtonProps={{
        onClick: onContinue,
        text: translate("general.continue"),
      }}
    />
  );
};
