/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AgeId } from './AgeId';
import {
    AgeIdFromJSON,
    AgeIdFromJSONTyped,
    AgeIdToJSON,
} from './AgeId';
import type { Languages } from './Languages';
import {
    LanguagesFromJSON,
    LanguagesFromJSONTyped,
    LanguagesToJSON,
} from './Languages';

/**
 * 
 * @export
 * @interface AgePrice
 */
export interface AgePrice {
    /**
     * 
     * @type {AgeId}
     * @memberof AgePrice
     */
    ageId: AgeId;
    /**
     * 
     * @type {Languages}
     * @memberof AgePrice
     */
    name: Languages;
    /**
     * 
     * @type {string}
     * @memberof AgePrice
     */
    suggestedPrice: string;
    /**
     * 
     * @type {number}
     * @memberof AgePrice
     */
    quantity: number;
    /**
     * 
     * @type {string}
     * @memberof AgePrice
     */
    subTotal: string;
    /**
     * 
     * @type {boolean}
     * @memberof AgePrice
     */
    requiresAdult?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AgePrice
     */
    confidentialPrice: string;
    /**
     * 
     * @type {string}
     * @memberof AgePrice
     */
    noShowPrice?: string | null;
}

/**
 * Check if a given object implements the AgePrice interface.
 */
export function instanceOfAgePrice(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "ageId" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "suggestedPrice" in value;
    isInstance = isInstance && "quantity" in value;
    isInstance = isInstance && "subTotal" in value;
    isInstance = isInstance && "confidentialPrice" in value;

    return isInstance;
}

export function AgePriceFromJSON(json: any): AgePrice {
    return AgePriceFromJSONTyped(json, false);
}

export function AgePriceFromJSONTyped(json: any, ignoreDiscriminator: boolean): AgePrice {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ageId': AgeIdFromJSON(json['ageId']),
        'name': LanguagesFromJSON(json['name']),
        'suggestedPrice': json['suggestedPrice'],
        'quantity': json['quantity'],
        'subTotal': json['subTotal'],
        'requiresAdult': !exists(json, 'requiresAdult') ? undefined : json['requiresAdult'],
        'confidentialPrice': json['confidentialPrice'],
        'noShowPrice': !exists(json, 'noShowPrice') ? undefined : json['noShowPrice'],
    };
}

export function AgePriceToJSON(value?: AgePrice | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ageId': AgeIdToJSON(value.ageId),
        'name': LanguagesToJSON(value.name),
        'suggestedPrice': value.suggestedPrice,
        'quantity': value.quantity,
        'subTotal': value.subTotal,
        'requiresAdult': value.requiresAdult,
        'confidentialPrice': value.confidentialPrice,
        'noShowPrice': value.noShowPrice,
    };
}

