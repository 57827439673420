/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Image } from './Image';
import {
    ImageFromJSON,
    ImageFromJSONTyped,
    ImageToJSON,
} from './Image';
import type { Languages } from './Languages';
import {
    LanguagesFromJSON,
    LanguagesFromJSONTyped,
    LanguagesToJSON,
} from './Languages';
import type { PricingSchedule } from './PricingSchedule';
import {
    PricingScheduleFromJSON,
    PricingScheduleFromJSONTyped,
    PricingScheduleToJSON,
} from './PricingSchedule';

/**
 * 
 * @export
 * @interface Option
 */
export interface Option {
    /**
     * 
     * @type {string}
     * @memberof Option
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof Option
     */
    onlyVisibleWhenAuthenticated?: boolean;
    /**
     * 
     * @type {Languages}
     * @memberof Option
     */
    name: Languages;
    /**
     * 
     * @type {Languages}
     * @memberof Option
     */
    description: Languages;
    /**
     * 
     * @type {Image}
     * @memberof Option
     */
    optionImage?: Image;
    /**
     * 
     * @type {Array<PricingSchedule>}
     * @memberof Option
     */
    pricingSchedules: Array<PricingSchedule>;
    /**
     * 
     * @type {number}
     * @memberof Option
     */
    maxTravelersPerOption: number | null;
}

/**
 * Check if a given object implements the Option interface.
 */
export function instanceOfOption(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "pricingSchedules" in value;
    isInstance = isInstance && "maxTravelersPerOption" in value;

    return isInstance;
}

export function OptionFromJSON(json: any): Option {
    return OptionFromJSONTyped(json, false);
}

export function OptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Option {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'onlyVisibleWhenAuthenticated': !exists(json, 'onlyVisibleWhenAuthenticated') ? undefined : json['onlyVisibleWhenAuthenticated'],
        'name': LanguagesFromJSON(json['name']),
        'description': LanguagesFromJSON(json['description']),
        'optionImage': !exists(json, 'optionImage') ? undefined : ImageFromJSON(json['optionImage']),
        'pricingSchedules': ((json['pricingSchedules'] as Array<any>).map(PricingScheduleFromJSON)),
        'maxTravelersPerOption': json['maxTravelersPerOption'],
    };
}

export function OptionToJSON(value?: Option | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'onlyVisibleWhenAuthenticated': value.onlyVisibleWhenAuthenticated,
        'name': LanguagesToJSON(value.name),
        'description': LanguagesToJSON(value.description),
        'optionImage': ImageToJSON(value.optionImage),
        'pricingSchedules': ((value.pricingSchedules as Array<any>).map(PricingScheduleToJSON)),
        'maxTravelersPerOption': value.maxTravelersPerOption,
    };
}

