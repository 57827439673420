/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ReservationStatus = {
    Pending: 'pending',
    Confirmed: 'confirmed',
    Cancelled: 'cancelled',
    OnHold: 'onHold'
} as const;
export type ReservationStatus = typeof ReservationStatus[keyof typeof ReservationStatus];


export function ReservationStatusFromJSON(json: any): ReservationStatus {
    return ReservationStatusFromJSONTyped(json, false);
}

export function ReservationStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReservationStatus {
    return json as ReservationStatus;
}

export function ReservationStatusToJSON(value?: ReservationStatus | null): any {
    return value as any;
}

