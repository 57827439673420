/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Languages } from './Languages';
import {
    LanguagesFromJSON,
    LanguagesFromJSONTyped,
    LanguagesToJSON,
} from './Languages';

/**
 * 
 * @export
 * @interface AdditionalInformation
 */
export interface AdditionalInformation {
    /**
     * 
     * @type {Languages}
     * @memberof AdditionalInformation
     */
    description: Languages;
}

/**
 * Check if a given object implements the AdditionalInformation interface.
 */
export function instanceOfAdditionalInformation(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "description" in value;

    return isInstance;
}

export function AdditionalInformationFromJSON(json: any): AdditionalInformation {
    return AdditionalInformationFromJSONTyped(json, false);
}

export function AdditionalInformationFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdditionalInformation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': LanguagesFromJSON(json['description']),
    };
}

export function AdditionalInformationToJSON(value?: AdditionalInformation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': LanguagesToJSON(value.description),
    };
}

