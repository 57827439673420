/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CardlessPaymentType } from './CardlessPaymentType';
import {
    CardlessPaymentTypeFromJSON,
    CardlessPaymentTypeFromJSONTyped,
    CardlessPaymentTypeToJSON,
} from './CardlessPaymentType';

/**
 * 
 * @export
 * @interface CashCardlessNotification
 */
export interface CashCardlessNotification {
    /**
     * 
     * @type {string}
     * @memberof CashCardlessNotification
     */
    amount: string;
    /**
     * 
     * @type {CardlessPaymentType}
     * @memberof CashCardlessNotification
     */
    type: CardlessPaymentType;
    /**
     * 
     * @type {string}
     * @memberof CashCardlessNotification
     */
    referenceNumber?: string;
}

/**
 * Check if a given object implements the CashCardlessNotification interface.
 */
export function instanceOfCashCardlessNotification(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "amount" in value;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function CashCardlessNotificationFromJSON(json: any): CashCardlessNotification {
    return CashCardlessNotificationFromJSONTyped(json, false);
}

export function CashCardlessNotificationFromJSONTyped(json: any, ignoreDiscriminator: boolean): CashCardlessNotification {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amount': json['amount'],
        'type': CardlessPaymentTypeFromJSON(json['type']),
        'referenceNumber': !exists(json, 'referenceNumber') ? undefined : json['referenceNumber'],
    };
}

export function CashCardlessNotificationToJSON(value?: CashCardlessNotification | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount': value.amount,
        'type': CardlessPaymentTypeToJSON(value.type),
        'referenceNumber': value.referenceNumber,
    };
}

