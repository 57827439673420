import { useState, useEffect } from "react";
import {
  doc,
  DocumentData,
  FirestoreError,
  onSnapshot,
} from "firebase/firestore";
import { firestore } from "../../firebase";
import { firebaseDataConverter } from "../../utils/firebase/firebaseDataConverter";

export const useDocumentData = <T extends DocumentData>(
  collection: string,
  docId?: string
): [T | undefined, boolean, FirestoreError | unknown] => {
  const [data, setData] = useState<T>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<FirestoreError | unknown>(null);

  useEffect(() => {
    setLoading(true);

    if (docId === undefined) {
      setLoading(false);
      return;
    }

    const documentRef = docId
      ? doc(firestore, `${collection}`, `${docId}`).withConverter(
          firebaseDataConverter<T>()
        )
      : doc(firestore, `${collection}`).withConverter(
          firebaseDataConverter<T>()
        );

    const unsubscribe = onSnapshot(
      documentRef,
      (documentSnapshot) => {
        if (documentSnapshot.exists()) {
          setData(documentSnapshot.data() as T);
        } else {
          console.error(`Document with ID ${docId} does not exist.`);
        }
        setLoading(false);
      },
      (err) => {
        setError(err);
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [docId]);

  return [data, loading, error];
};
