/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostSendToContactBody
 */
export interface PostSendToContactBody {
    /**
     * 
     * @type {string}
     * @memberof PostSendToContactBody
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof PostSendToContactBody
     */
    paymentLink: string;
    /**
     * 
     * @type {string}
     * @memberof PostSendToContactBody
     */
    amount?: string;
}

/**
 * Check if a given object implements the PostSendToContactBody interface.
 */
export function instanceOfPostSendToContactBody(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "paymentLink" in value;

    return isInstance;
}

export function PostSendToContactBodyFromJSON(json: any): PostSendToContactBody {
    return PostSendToContactBodyFromJSONTyped(json, false);
}

export function PostSendToContactBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostSendToContactBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': json['email'],
        'paymentLink': json['paymentLink'],
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
    };
}

export function PostSendToContactBodyToJSON(value?: PostSendToContactBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'paymentLink': value.paymentLink,
        'amount': value.amount,
    };
}

