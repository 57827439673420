/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HostBilling
 */
export interface HostBilling {
    /**
     * 
     * @type {number}
     * @memberof HostBilling
     */
    commissionPercentage: number;
    /**
     * 
     * @type {string}
     * @memberof HostBilling
     */
    commissionAmount?: string;
}

/**
 * Check if a given object implements the HostBilling interface.
 */
export function instanceOfHostBilling(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "commissionPercentage" in value;

    return isInstance;
}

export function HostBillingFromJSON(json: any): HostBilling {
    return HostBillingFromJSONTyped(json, false);
}

export function HostBillingFromJSONTyped(json: any, ignoreDiscriminator: boolean): HostBilling {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'commissionPercentage': json['commissionPercentage'],
        'commissionAmount': !exists(json, 'commissionAmount') ? undefined : json['commissionAmount'],
    };
}

export function HostBillingToJSON(value?: HostBilling | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'commissionPercentage': value.commissionPercentage,
        'commissionAmount': value.commissionAmount,
    };
}

