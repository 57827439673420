/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OpenpayTokenCard } from './OpenpayTokenCard';
import {
    OpenpayTokenCardFromJSON,
    OpenpayTokenCardFromJSONTyped,
    OpenpayTokenCardToJSON,
} from './OpenpayTokenCard';

/**
 * 
 * @export
 * @interface OpenpayToken
 */
export interface OpenpayToken {
    /**
     * 
     * @type {string}
     * @memberof OpenpayToken
     */
    id: string;
    /**
     * 
     * @type {OpenpayTokenCard}
     * @memberof OpenpayToken
     */
    card: OpenpayTokenCard;
}

/**
 * Check if a given object implements the OpenpayToken interface.
 */
export function instanceOfOpenpayToken(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "card" in value;

    return isInstance;
}

export function OpenpayTokenFromJSON(json: any): OpenpayToken {
    return OpenpayTokenFromJSONTyped(json, false);
}

export function OpenpayTokenFromJSONTyped(json: any, ignoreDiscriminator: boolean): OpenpayToken {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'card': OpenpayTokenCardFromJSON(json['card']),
    };
}

export function OpenpayTokenToJSON(value?: OpenpayToken | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'card': OpenpayTokenCardToJSON(value.card),
    };
}

