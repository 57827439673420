import React, { createContext, useContext } from "react";
import { isString } from "lodash";
import { useLocation, useNavigate } from "react-router-dom";

interface Context {
  currentLanguageCode: LanguageCode;
  onChangeLanguage: (languageCode: LanguageCode) => void;
}

const LanguageContext = createContext<Context>({
  currentLanguageCode: "en",
  onChangeLanguage: (languageCode) => console.log(languageCode),
});

interface Props {
  children: React.ReactElement | React.ReactElement[];
}

export const LanguageProvider = ({ children }: Props): React.ReactElement => {
  const location = useLocation();
  const navigate = useNavigate();

  const [languageCode] = location.pathname.slice(1).split("/");

  const currentLanguageCode: LanguageCode = isLanguageCode(languageCode)
    ? languageCode
    : "en";

  const onChangeLanguage: Context["onChangeLanguage"] = (languageCode) => {
    const regex = new RegExp(`/${currentLanguageCode}/`, "g");

    const newPathname =
      location.pathname === "/"
        ? `${location.pathname}${languageCode}/`
        : location.pathname.replace(regex, `/${languageCode}/`);

    navigate(newPathname + location.search);
  };

  return (
    <LanguageContext.Provider value={{ currentLanguageCode, onChangeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = (): Context => useContext(LanguageContext);

const isLanguageCode = (data: unknown): data is LanguageCode =>
  isString(data) && ["es", "en", "pt"].includes(data);
