import React, { createContext, useContext, useEffect, useState } from "react";
import { useAuthentication } from "./AuthenticationProvider";
import { useGlobalData } from "./GlobalDataProvider";
import { useAcl } from "../hooks";
import { Button, Modal, Select, SelectOption, Title } from "../components";
import { Space } from "antd";

interface Context {
  currentOffice: Office | null;
  onChangeOffice: (officeId: string) => void;
}

const OfficeContext = createContext<Context>({
  currentOffice: null,
  onChangeOffice: console.info,
});

interface Props {
  children: React.ReactElement;
}

export const OfficeProvider = ({ children }: Props): React.ReactElement => {
  const { authUser } = useAuthentication();
  const { offices } = useGlobalData();
  const { aclCheck } = useAcl();

  const [currentOffice, setCurrentOffice] = useState<Context["currentOffice"]>(
    null
  );
  const [currentOfficeId, setCurrentOfficeId] = useState<string>();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  useEffect(() => {
    if (!currentOffice && authUser && offices.length > 0)
      initializeCurrentOffice(authUser, offices);
  }, [authUser, offices, currentOffice]);

  const officesView = (authUser: AuthUser, offices: Office[]): Office[] =>
    offices
      .filter((office) => office.agencyId === authUser.agency?.id)
      .filter((office) => office.cityId === authUser.city?.id);

  const initializeCurrentOffice = (authUser: AuthUser, offices: Office[]) => {
    setCurrentOffice(null);

    if (officesView(authUser, offices).length === 1)
      return setCurrentOffice(officesView(authUser, offices)[0]);

    if (!aclCheck("selectOfficeInProvider")) return;

    setIsModalVisible(true);
  };

  const onChangeOffice: Context["onChangeOffice"] = (officeId) => {
    const office = offices.find((office) => office.id === officeId);

    if (office) setCurrentOffice(office);
  };

  const onClickConfirm = (): void => {
    if (currentOfficeId) {
      onChangeOffice(currentOfficeId);
      setIsModalVisible(false);
      setCurrentOfficeId(undefined);
    }
  };

  return (
    <OfficeContext.Provider value={{ currentOffice, onChangeOffice }}>
      <Modal visible={isModalVisible} closable={false}>
        <Space direction="vertical" size="middle" style={{ display: "flex" }}>
          <Title level={2} align="center">
            {authUser?.agency?.name}
          </Title>
          <Title level={3} align="center">
            Select your office
          </Title>
          {authUser && (
            <Select
              label="Office"
              value={currentOfficeId}
              onChange={(value) => setCurrentOfficeId(value)}
            >
              {officesView(authUser, offices).map((officeView) => (
                <SelectOption key={officeView.id} value={officeView.id}>
                  {officeView.name}
                </SelectOption>
              ))}
            </Select>
          )}
          <Button onClick={onClickConfirm} block type="primary">
            Confirm
          </Button>
        </Space>
      </Modal>
      {children}
    </OfficeContext.Provider>
  );
};

export const useOffice = (): Context => useContext(OfficeContext);
