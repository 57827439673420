/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Make all properties in T optional
 * @export
 * @interface PartialRecordAgeIdNumber
 */
export interface PartialRecordAgeIdNumber {
    /**
     * 
     * @type {number}
     * @memberof PartialRecordAgeIdNumber
     */
    adult?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialRecordAgeIdNumber
     */
    youth?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialRecordAgeIdNumber
     */
    child?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialRecordAgeIdNumber
     */
    senior?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialRecordAgeIdNumber
     */
    student?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialRecordAgeIdNumber
     */
    infant?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialRecordAgeIdNumber
     */
    other1?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialRecordAgeIdNumber
     */
    other2?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialRecordAgeIdNumber
     */
    other3?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialRecordAgeIdNumber
     */
    other4?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialRecordAgeIdNumber
     */
    other5?: number;
}

/**
 * Check if a given object implements the PartialRecordAgeIdNumber interface.
 */
export function instanceOfPartialRecordAgeIdNumber(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PartialRecordAgeIdNumberFromJSON(json: any): PartialRecordAgeIdNumber {
    return PartialRecordAgeIdNumberFromJSONTyped(json, false);
}

export function PartialRecordAgeIdNumberFromJSONTyped(json: any, ignoreDiscriminator: boolean): PartialRecordAgeIdNumber {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'adult': !exists(json, 'adult') ? undefined : json['adult'],
        'youth': !exists(json, 'youth') ? undefined : json['youth'],
        'child': !exists(json, 'child') ? undefined : json['child'],
        'senior': !exists(json, 'senior') ? undefined : json['senior'],
        'student': !exists(json, 'student') ? undefined : json['student'],
        'infant': !exists(json, 'infant') ? undefined : json['infant'],
        'other1': !exists(json, 'other1') ? undefined : json['other1'],
        'other2': !exists(json, 'other2') ? undefined : json['other2'],
        'other3': !exists(json, 'other3') ? undefined : json['other3'],
        'other4': !exists(json, 'other4') ? undefined : json['other4'],
        'other5': !exists(json, 'other5') ? undefined : json['other5'],
    };
}

export function PartialRecordAgeIdNumberToJSON(value?: PartialRecordAgeIdNumber | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'adult': value.adult,
        'youth': value.youth,
        'child': value.child,
        'senior': value.senior,
        'student': value.student,
        'infant': value.infant,
        'other1': value.other1,
        'other2': value.other2,
        'other3': value.other3,
        'other4': value.other4,
        'other5': value.other5,
    };
}

