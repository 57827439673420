/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ContactPhone } from './ContactPhone';
import {
    ContactPhoneFromJSON,
    ContactPhoneFromJSONTyped,
    ContactPhoneToJSON,
} from './ContactPhone';

/**
 * 
 * @export
 * @interface PostReservationBodyContact
 */
export interface PostReservationBodyContact {
    /**
     * 
     * @type {string}
     * @memberof PostReservationBodyContact
     */
    email: string;
    /**
     * 
     * @type {ContactPhone}
     * @memberof PostReservationBodyContact
     */
    phone: ContactPhone;
}

/**
 * Check if a given object implements the PostReservationBodyContact interface.
 */
export function instanceOfPostReservationBodyContact(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "phone" in value;

    return isInstance;
}

export function PostReservationBodyContactFromJSON(json: any): PostReservationBodyContact {
    return PostReservationBodyContactFromJSONTyped(json, false);
}

export function PostReservationBodyContactFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostReservationBodyContact {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': json['email'],
        'phone': ContactPhoneFromJSON(json['phone']),
    };
}

export function PostReservationBodyContactToJSON(value?: PostReservationBodyContact | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'phone': ContactPhoneToJSON(value.phone),
    };
}

