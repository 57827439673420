/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ReservationPickupType = {
    Pickup: 'pickup',
    DecideLater: 'decide_later',
    MeetingPoint: 'meeting_point'
} as const;
export type ReservationPickupType = typeof ReservationPickupType[keyof typeof ReservationPickupType];


export function ReservationPickupTypeFromJSON(json: any): ReservationPickupType {
    return ReservationPickupTypeFromJSONTyped(json, false);
}

export function ReservationPickupTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReservationPickupType {
    return json as ReservationPickupType;
}

export function ReservationPickupTypeToJSON(value?: ReservationPickupType | null): any {
    return value as any;
}

