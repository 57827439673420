/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Einvoicing } from './Einvoicing';
import {
    EinvoicingFromJSON,
    EinvoicingFromJSONTyped,
    EinvoicingToJSON,
} from './Einvoicing';

/**
 * 
 * @export
 * @interface PutReservationReceiptBody
 */
export interface PutReservationReceiptBody {
    /**
     * 
     * @type {Einvoicing}
     * @memberof PutReservationReceiptBody
     */
    einvoicing: Einvoicing;
}

/**
 * Check if a given object implements the PutReservationReceiptBody interface.
 */
export function instanceOfPutReservationReceiptBody(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "einvoicing" in value;

    return isInstance;
}

export function PutReservationReceiptBodyFromJSON(json: any): PutReservationReceiptBody {
    return PutReservationReceiptBodyFromJSONTyped(json, false);
}

export function PutReservationReceiptBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutReservationReceiptBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'einvoicing': EinvoicingFromJSON(json['einvoicing']),
    };
}

export function PutReservationReceiptBodyToJSON(value?: PutReservationReceiptBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'einvoicing': EinvoicingToJSON(value.einvoicing),
    };
}

