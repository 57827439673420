import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { MenuItem } from "../MenuItem";
import { MenuItemsGroup } from "../MenuItemsGroup";
import { Favicon } from "../../../images";
import {
  faBookCopy,
  faBuilding,
  faBuildings,
  faCopy,
  faEarthAmericas,
  faLocationDot,
  faRoute,
  faSignOutAlt,
  faTags,
  faTicket,
  faUnlock,
  faUsers,
  faUsersBetweenLines,
  faUserTag,
  faHotel,
} from "@fortawesome/pro-solid-svg-icons";
import { Title } from "../../Title";
import { Drawer, Row, Space, Typography } from "antd";
import { Acl } from "../../Acl";
import {
  useAuthentication,
  useLanguage,
  useOperator,
  useVersion,
} from "../../../providers";
import UrlAssembler from "url-assembler";
import { pathnameWithLanguage } from "../../../utils";

const { Text } = Typography;

interface Props {
  setIsVisibleMenu: (isVisibleMenu: boolean) => void;
  isVisibleMenu: boolean;
}

export const PrivatePageMenu = ({
  setIsVisibleMenu,
  isVisibleMenu,
}: Props): JSX.Element => {
  const navigate = useNavigate();

  const { version } = useVersion();
  const { logout, authUser } = useAuthentication();
  const { currentOperator } = useOperator();
  const { currentLanguageCode } = useLanguage();

  const onLogout = () => {
    logout().then(() => {
      navigate(pathnameWithLanguage(currentLanguageCode, "/login"));
    });
  };

  const onClickMenu = (pathname: string) => {
    setIsVisibleMenu(false);

    navigate(pathnameWithLanguage(currentLanguageCode, `/u${pathname}`));
  };

  const onClickHome = () =>
    navigate(pathnameWithLanguage(currentLanguageCode, "/"));

  const operatorPathname = (pathname: string) => {
    const basePathname = new UrlAssembler()
      .template("/operators/:operatorId")
      .param({
        operatorId: currentOperator?.id,
      })
      .toString();

    return basePathname + pathname;
  };

  return (
    <DrawerStyled
      title={
        <DrawerTitle gutter={[0, 0]} align="middle">
          <img src={Favicon} alt="icon" onClick={onClickHome} />
          <Space direction="vertical" align="center">
            <Title level={5} fontcolor={(theme) => theme.colors.white}>
              Find Local Tours
            </Title>
            <Text>V. {version}</Text>
          </Space>
        </DrawerTitle>
      }
      placement="left"
      closable={true}
      onClose={() => setIsVisibleMenu(false)}
      open={isVisibleMenu}
    >
      {authUser?.role.code === "administrator" && (
        <MenuItemsGroup label="Administrator">
          <Acl aclId="reservationsPage">
            <MenuItem
              label="Reservations"
              icon={faCopy}
              onClick={() => onClickMenu("/reservations")}
            />
          </Acl>
          <Acl aclId="manageOperatorsAvailabilityPage">
            <MenuItem
              label="Manage operators availability"
              icon={faUnlock}
              onClick={() => onClickMenu("/manage-operators-availability")}
            />
          </Acl>
          <Acl aclId="operatorsPage">
            <MenuItem
              label="Operators"
              icon={faBuildings}
              onClick={() => onClickMenu("/operators")}
            />
          </Acl>
          <Acl aclId="activitiesPage">
            <MenuItem
              label="Activities"
              icon={faTags}
              onClick={() => onClickMenu("/activities")}
            />
          </Acl>
          <Acl aclId="discountsPage">
            <MenuItem
              label="Discounts"
              icon={faTicket}
              onClick={() => onClickMenu("/discounts")}
            />
          </Acl>
          <Acl aclId="countriesPage">
            <MenuItem
              label="Countries/cities"
              icon={faEarthAmericas}
              onClick={() => onClickMenu("/countries")}
            />
          </Acl>
          <Acl aclId="pickupLocationsPage">
            <MenuItem
              label="Pickup locations"
              icon={faLocationDot}
              onClick={() => onClickMenu("/pickup-locations")}
            />
          </Acl>
          <Acl aclId="usersPage">
            <MenuItem
              label="Users"
              icon={faUsers}
              onClick={() => onClickMenu("/users")}
            />
          </Acl>
          <Acl aclId="hostsPage">
            <MenuItem
              label="Hosts"
              icon={faHotel}
              onClick={() => onClickMenu("/hosts")}
            />
          </Acl>
          <Acl aclId="agenciesPage">
            <MenuItem
              label="Agencies"
              icon={faBuilding}
              onClick={() => onClickMenu("/agencies")}
            />
          </Acl>
          <Acl aclId="defaultRolesAclsPage">
            <MenuItem
              label="Default roles acls"
              icon={faUserTag}
              onClick={() => onClickMenu("/default-roles-acls")}
            />
          </Acl>
        </MenuItemsGroup>
      )}
      <MenuItemsGroup label="Operator">
        <Acl aclId="operatorsPage">
          <MenuItem
            label="Operator"
            icon={faBuilding}
            onClick={() => onClickMenu(operatorPathname(""))}
          />
        </Acl>
        <Acl aclId="reservationsPage">
          <MenuItem
            label="Reservations"
            icon={faCopy}
            onClick={() => onClickMenu(operatorPathname("/reservations"))}
          />
        </Acl>
        <Acl aclId="manageToursAvailabilityPage">
          <MenuItem
            label="Manage tours availability"
            icon={faUnlock}
            onClick={() =>
              onClickMenu(operatorPathname("/manage-tours-availability"))
            }
          />
        </Acl>
        <Acl aclId="optionsAvailabilityPage">
          <MenuItem
            label="Options availability"
            icon={faUnlock}
            onClick={() =>
              onClickMenu(operatorPathname("/options-availability"))
            }
          />
        </Acl>
        <Acl aclId="toursPage">
          <MenuItem
            label="Tours"
            icon={faRoute}
            onClick={() => onClickMenu(operatorPathname("/tours"))}
          />
        </Acl>
        <Acl aclId="travelersPage">
          <MenuItem
            label="Travelers"
            icon={faUsersBetweenLines}
            onClick={() => onClickMenu(operatorPathname("/travelers"))}
          />
        </Acl>
        <Acl aclId="reservationsSummaryPage">
          <MenuItem
            label="Reservations summary"
            icon={faBookCopy}
            onClick={() =>
              onClickMenu(operatorPathname("/reservations-summary"))
            }
          />
        </Acl>
      </MenuItemsGroup>
      <MenuItem
        label="Logout"
        icon={faSignOutAlt}
        onClick={onLogout}
        backgroundHover="#f83030"
      />
    </DrawerStyled>
  );
};

export const DrawerStyled = styled(Drawer)`
  .ant-drawer-content-wrapper {
    width: 260px !important;

    .ant-drawer-content {
      background-color: ${({ theme }) => theme.colors.tertiary};

      .ant-drawer-wrapper-body {
        .ant-drawer-header {
          background-color: ${({ theme }) => theme.colors.tertiary};
          padding: 12px 20px;
          border-bottom: none;

          .ant-drawer-header-title {
            width: 100%;
            align-items: start;
          }

          .ant-drawer-title {
            color: ${({ theme }) => theme.colors.light};
          }

          .ant-drawer-close {
            color: ${({ theme }) => theme.colors.light};
            font-weight: 600;
            order: 1;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin: 0;
          }
        }

        .ant-drawer-body {
          padding: 0;
        }
      }
    }
  }
`;

const DrawerTitle = styled(Row)`
  display: flex;
  justify-content: center;

  .ant-space {
    gap: 0 !important;
  }

  .ant-space {
    .ant-space-item {
      margin: 0 !important;

      .ant-typography {
        margin: 0;
        color: ${({ theme }) => theme.colors.white};
      }

      .ant-typography {
        font-size: 1.1em !important;
      }
    }

    .ant-space-item:last-child {
      .ant-typography {
        font-size: 0.9em;
      }
    }
  }

  img {
    width: 100px;
    height: auto;
    object-fit: contain;
    margin: 0.2rem 0;
  }
`;
