import React from "react";
import styled, { css, DefaultTheme } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { tint } from "polished";
import { Tooltip } from "antd";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface IconWrapperProps extends React.HTMLAttributes<HTMLDivElement> {
  size: number;
  styled: {
    color?: (theme: DefaultTheme) => string;
  };
}

interface Props extends Partial<IconWrapperProps> {
  icon: IconProp;
  tooltipTitle?: string;
  disabled?: boolean;
}

export const IconAction = ({
  icon,
  tooltipTitle,
  size = 38,
  styled = {},
  onClick,
  ...props
}: Props): JSX.Element => (
  <Tooltip placement="top" title={tooltipTitle}>
    <IconWrapper onClick={onClick} size={size} styled={styled} {...props}>
      <FontAwesomeIcon icon={icon} />
    </IconWrapper>
  </Tooltip>
);

const IconWrapper = styled.div<IconWrapperProps>`
  ${({
    theme,
    size,
    onClick,
    styled: { color = () => theme.colors.black },
  }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: ${theme.border_radius.percentage_medium};
    height: ${size}px;
    width: ${size}px;
    color: ${color(theme)};

    ${onClick &&
    css`
      cursor: pointer;

      &:hover {
        border-radius: ${theme.border_radius.percentage_full};
        background: ${tint(0.87, color(theme))};
      }
    `}
    .svg-inline--fa {
      height: ${onClick ? size * 0.55 : size}px;
      width: ${onClick ? size * 0.55 : size}px;
    }
  `}
`;
