/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExtraGroup } from './ExtraGroup';
import {
    ExtraGroupFromJSON,
    ExtraGroupFromJSONTyped,
    ExtraGroupToJSON,
} from './ExtraGroup';
import type { Languages } from './Languages';
import {
    LanguagesFromJSON,
    LanguagesFromJSONTyped,
    LanguagesToJSON,
} from './Languages';

/**
 * 
 * @export
 * @interface Extra
 */
export interface Extra {
    /**
     * 
     * @type {string}
     * @memberof Extra
     */
    id: string;
    /**
     * 
     * @type {Languages}
     * @memberof Extra
     */
    name: Languages;
    /**
     * 
     * @type {Languages}
     * @memberof Extra
     */
    information?: Languages;
    /**
     * 
     * @type {string}
     * @memberof Extra
     */
    price: string;
    /**
     * 
     * @type {string}
     * @memberof Extra
     */
    confidentialPrice: string;
    /**
     * 
     * @type {string}
     * @memberof Extra
     */
    noShowPrice: string | null;
    /**
     * 
     * @type {ExtraGroup}
     * @memberof Extra
     */
    group?: ExtraGroup;
}

/**
 * Check if a given object implements the Extra interface.
 */
export function instanceOfExtra(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "price" in value;
    isInstance = isInstance && "confidentialPrice" in value;
    isInstance = isInstance && "noShowPrice" in value;

    return isInstance;
}

export function ExtraFromJSON(json: any): Extra {
    return ExtraFromJSONTyped(json, false);
}

export function ExtraFromJSONTyped(json: any, ignoreDiscriminator: boolean): Extra {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': LanguagesFromJSON(json['name']),
        'information': !exists(json, 'information') ? undefined : LanguagesFromJSON(json['information']),
        'price': json['price'],
        'confidentialPrice': json['confidentialPrice'],
        'noShowPrice': json['noShowPrice'],
        'group': !exists(json, 'group') ? undefined : ExtraGroupFromJSON(json['group']),
    };
}

export function ExtraToJSON(value?: Extra | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': LanguagesToJSON(value.name),
        'information': LanguagesToJSON(value.information),
        'price': value.price,
        'confidentialPrice': value.confidentialPrice,
        'noShowPrice': value.noShowPrice,
        'group': ExtraGroupToJSON(value.group),
    };
}

