import React from "react";
import styled, { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface MenuItemStyledProps
  extends React.InputHTMLAttributes<HTMLDivElement> {
  backgroundHover?: string;
}

interface Props extends Partial<MenuItemStyledProps> {
  label: string;
  icon: IconProp;
}

export const MenuItem = ({
  label,
  icon,
  onClick,
  backgroundHover,
}: Props): JSX.Element => (
  <MenuItemStyled onClick={onClick} backgroundHover={backgroundHover}>
    <FontAwesomeIcon icon={icon} fixedWidth size="lg" /> {label}
  </MenuItemStyled>
);

const MenuItemStyled = styled.div<MenuItemStyledProps>`
  ${({ theme, backgroundHover = theme.colors.primary }) => css`
    cursor: pointer;
    display: flex;
    align-items: center;
    width: auto;
    height: 45px;
    padding: 0.5rem 1rem;
    margin: 0.3rem 0.8rem;
    border: none;
    box-sizing: border-box;
    border-radius: ${theme.border_radius.xxx_large};
    color: ${theme.colors.white};

    &:hover {
      box-shadow: ${backgroundHover};
      background: ${backgroundHover};
      color: #000;
    }

    svg {
      margin-right: 1rem;
      display: block;
    }
  `}
`;
