import type { Locale } from "antd/es/locale-provider";
import React from "react";
import "antd/dist/antd.min.css";
import { useLanguage } from "./LanguageProvider";
import { ConfigProvider } from "antd";
import enUS from "antd/es/locale/en_US";
import esES from "antd/es/locale/es_ES";
import ptBR from "antd/es/locale/pt_BR";

const locales: Record<LanguageCode, Locale> = {
  es: esES,
  en: enUS,
  pt: ptBR,
};

interface Props {
  children: React.ReactElement | React.ReactElement[];
}

export const AntdInitializer = ({ children }: Props): React.ReactElement => {
  const { currentLanguageCode } = useLanguage();

  return (
    <ConfigProvider locale={locales[currentLanguageCode]}>
      {children}
    </ConfigProvider>
  );
};
