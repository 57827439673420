/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { City } from './City';
import {
    CityFromJSON,
    CityFromJSONTyped,
    CityToJSON,
} from './City';
import type { MeetingPickup } from './MeetingPickup';
import {
    MeetingPickupFromJSON,
    MeetingPickupFromJSONTyped,
    MeetingPickupToJSON,
} from './MeetingPickup';
import type { MeetingPoint } from './MeetingPoint';
import {
    MeetingPointFromJSON,
    MeetingPointFromJSONTyped,
    MeetingPointToJSON,
} from './MeetingPoint';
import type { MeetingPointDescription } from './MeetingPointDescription';
import {
    MeetingPointDescriptionFromJSON,
    MeetingPointDescriptionFromJSONTyped,
    MeetingPointDescriptionToJSON,
} from './MeetingPointDescription';
import type { ZoneRange } from './ZoneRange';
import {
    ZoneRangeFromJSON,
    ZoneRangeFromJSONTyped,
    ZoneRangeToJSON,
} from './ZoneRange';

/**
 * 
 * @export
 * @interface PartialTourMeeting
 */
export interface PartialTourMeeting {
    /**
     * 
     * @type {City}
     * @memberof PartialTourMeeting
     */
    city: City;
    /**
     * 
     * @type {MeetingPointDescription}
     * @memberof PartialTourMeeting
     */
    description: MeetingPointDescription | null;
    /**
     * 
     * @type {string}
     * @memberof PartialTourMeeting
     */
    type: PartialTourMeetingTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof PartialTourMeeting
     */
    minutesBeforeMeeting: number;
    /**
     * 
     * @type {MeetingPointDescription}
     * @memberof PartialTourMeeting
     */
    point: MeetingPointDescription | null;
    /**
     * 
     * @type {Array<ZoneRange>}
     * @memberof PartialTourMeeting
     */
    zoneRanges?: Array<ZoneRange>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PartialTourMeeting
     */
    pickupLocationsIds?: Array<string>;
}


/**
 * @export
 */
export const PartialTourMeetingTypeEnum = {
    Pickup: 'pickup'
} as const;
export type PartialTourMeetingTypeEnum = typeof PartialTourMeetingTypeEnum[keyof typeof PartialTourMeetingTypeEnum];


/**
 * Check if a given object implements the PartialTourMeeting interface.
 */
export function instanceOfPartialTourMeeting(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "city" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "minutesBeforeMeeting" in value;
    isInstance = isInstance && "point" in value;

    return isInstance;
}

export function PartialTourMeetingFromJSON(json: any): PartialTourMeeting {
    return PartialTourMeetingFromJSONTyped(json, false);
}

export function PartialTourMeetingFromJSONTyped(json: any, ignoreDiscriminator: boolean): PartialTourMeeting {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'city': CityFromJSON(json['city']),
        'description': MeetingPointDescriptionFromJSON(json['description']),
        'type': json['type'],
        'minutesBeforeMeeting': json['minutesBeforeMeeting'],
        'point': MeetingPointDescriptionFromJSON(json['point']),
        'zoneRanges': !exists(json, 'zoneRanges') ? undefined : ((json['zoneRanges'] as Array<any>).map(ZoneRangeFromJSON)),
        'pickupLocationsIds': !exists(json, 'pickupLocationsIds') ? undefined : json['pickupLocationsIds'],
    };
}

export function PartialTourMeetingToJSON(value?: PartialTourMeeting | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'city': CityToJSON(value.city),
        'description': MeetingPointDescriptionToJSON(value.description),
        'type': value.type,
        'minutesBeforeMeeting': value.minutesBeforeMeeting,
        'point': MeetingPointDescriptionToJSON(value.point),
        'zoneRanges': value.zoneRanges === undefined ? undefined : ((value.zoneRanges as Array<any>).map(ZoneRangeToJSON)),
        'pickupLocationsIds': value.pickupLocationsIds,
    };
}

