import type { LocaleObject } from "yup/lib/locale";
import React, { useEffect } from "react";
import { setLocale } from "yup";
import { useLanguage } from "./LanguageProvider";

export const YupInitializer = (): React.ReactElement => {
  const { currentLanguageCode } = useLanguage();

  const locales: Record<LanguageCode, LocaleObject> = {
    es: esLocale,
    en: enLocale,
    pt: ptLocale,
  };

  useEffect(() => {
    setLocale(locales[currentLanguageCode]);
  }, []);

  return <React.Fragment />;
};

const esLocale: LocaleObject = {
  mixed: {
    default: "El valor no es válido",
    required: "Este campo es obligatorio",
    oneOf: "Debe ser uno de los siguientes valores: ${values}",
    notOneOf: "No puede ser uno de los siguientes valores: ${values}",
    notType: "Debe ser un ${type}",
  },
  string: {
    length: "Debe tener exactamente ${length} caracteres",
    min: "Debe tener al menos ${min} caracteres",
    max: "Debe tener como máximo ${max} caracteres",
    email: "Debe ser un correo electrónico válido",
    url: "Debe ser una URL válida",
    trim: "No debe contener espacios en blanco al principio o al final",
    lowercase: "Debe estar en minúsculas",
    uppercase: "Debe estar en mayúsculas",
  },
  number: {
    min: "Debe ser igual o mayor que ${min}",
    max: "Debe ser igual o menor que ${max}",
    lessThan: "Debe ser menor que ${less}",
    moreThan: "Debe ser mayor que ${more}",
    positive: "Debe ser un número positivo",
    negative: "Debe ser un número negativo",
    integer: "Debe ser un número entero",
  },
  date: {
    min: "Debe ser igual o posterior a ${min}",
    max: "Debe ser igual o anterior a ${max}",
  },
  boolean: {
    isValue: "Este campo es obligatorio",
  },
  object: {
    noUnknown: "Contiene claves no permitidas",
  },
  array: {
    min: "Debe tener al menos ${min} elementos",
    max: "Debe tener como máximo ${max} elementos",
  },
};

const enLocale: LocaleObject = {
  mixed: {
    default: "The value is not valid",
    required: "This field is required",
    oneOf: "It must be one of the following values: ${values}",
    notOneOf: "It cannot be one of the following values: ${values}",
    notType: "It must be a ${type}",
  },
  string: {
    length: "It must have exactly ${length} characters",
    min: "It must have at least ${min} characters",
    max: "It must have at most ${max} characters",
    email: "It must be a valid email",
    url: "It must be a valid URL",
    trim: "It must not contain white spaces at the beginning or end",
    lowercase: "It must be in lowercase",
    uppercase: "It must be in uppercase",
  },
  number: {
    min: "It must be equal to or greater than ${min}",
    max: "It must be equal to or less than ${max}",
    lessThan: "It must be less than ${less}",
    moreThan: "It must be greater than ${more}",
    positive: "It must be a positive number",
    negative: "It must be a negative number",
    integer: "It must be an integer",
  },
  date: {
    min: "It must be equal to or later than ${min}",
    max: "It must be equal to or earlier than ${max}",
  },
  boolean: {
    isValue: "This field is required",
  },
  object: {
    noUnknown: "Contains disallowed keys",
  },
  array: {
    min: "It must have at least ${min} elements",
    max: "It must have at most ${max} elements",
  },
};

const ptLocale: LocaleObject = {
  mixed: {
    default: "O valor não é válido",
    required: "Este campo é obrigatório",
    oneOf: "Deve ser um dos seguintes valores: ${values}",
    notOneOf: "Não pode ser um dos seguintes valores: ${values}",
    notType: "Deve ser um ${type}",
  },
  string: {
    length: "Deve ter exatamente ${length} caracteres",
    min: "Deve ter pelo menos ${min} caracteres",
    max: "Deve ter no máximo ${max} caracteres",
    email: "Deve ser um email válido",
    url: "Deve ser uma URL válida",
    trim: "Não deve conter espaços em branco no início ou no final",
    lowercase: "Deve estar em minúsculas",
    uppercase: "Deve estar em letras maiúsculas",
  },
  number: {
    min: "Deve ser igual ou maior que ${min}",
    max: "Deve ser igual ou menor que ${max}",
    lessThan: "Deve ser menor que ${less}",
    moreThan: "Deve ser maior que ${more}",
    positive: "Deve ser um número positivo",
    negative: "Deve ser um número negativo",
    integer: "Deve ser um número inteiro",
  },
  date: {
    min: "Deve ser igual ou posterior a ${min}",
    max: "Deve ser igual ou anterior a ${max}",
  },
  boolean: {
    isValue: "Este campo é obrigatório",
  },
  object: {
    noUnknown: "Contém chaves não permitidas",
  },
  array: {
    min: "Deve ter pelo menos ${min} elementos",
    max: "Deve ter no máximo ${max} elementos",
  },
};
