/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AgeBilling } from './AgeBilling';
import {
    AgeBillingFromJSON,
    AgeBillingFromJSONTyped,
    AgeBillingToJSON,
} from './AgeBilling';
import type { BillingCardFee } from './BillingCardFee';
import {
    BillingCardFeeFromJSON,
    BillingCardFeeFromJSONTyped,
    BillingCardFeeToJSON,
} from './BillingCardFee';
import type { HostBilling } from './HostBilling';
import {
    HostBillingFromJSON,
    HostBillingFromJSONTyped,
    HostBillingToJSON,
} from './HostBilling';

/**
 * 
 * @export
 * @interface Billing
 */
export interface Billing {
    /**
     * 
     * @type {string}
     * @memberof Billing
     */
    totalSubAmount: string;
    /**
     * 
     * @type {string}
     * @memberof Billing
     */
    totalLiquidationPrice: string;
    /**
     * 
     * @type {string}
     * @memberof Billing
     */
    discountAmount: string;
    /**
     * 
     * @type {string}
     * @memberof Billing
     */
    totalExtras?: string;
    /**
     * 
     * @type {string}
     * @memberof Billing
     */
    totalAmount: string;
    /**
     * 
     * @type {BillingCardFee}
     * @memberof Billing
     */
    cardFee: BillingCardFee | null;
    /**
     * 
     * @type {string}
     * @memberof Billing
     */
    cardFeeAmount: string;
    /**
     * 
     * @type {string}
     * @memberof Billing
     */
    totalPrice: string;
    /**
     * 
     * @type {Array<AgeBilling>}
     * @memberof Billing
     */
    ages: Array<AgeBilling>;
    /**
     * 
     * @type {HostBilling}
     * @memberof Billing
     */
    host?: HostBilling;
}

/**
 * Check if a given object implements the Billing interface.
 */
export function instanceOfBilling(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "totalSubAmount" in value;
    isInstance = isInstance && "totalLiquidationPrice" in value;
    isInstance = isInstance && "discountAmount" in value;
    isInstance = isInstance && "totalAmount" in value;
    isInstance = isInstance && "cardFee" in value;
    isInstance = isInstance && "cardFeeAmount" in value;
    isInstance = isInstance && "totalPrice" in value;
    isInstance = isInstance && "ages" in value;

    return isInstance;
}

export function BillingFromJSON(json: any): Billing {
    return BillingFromJSONTyped(json, false);
}

export function BillingFromJSONTyped(json: any, ignoreDiscriminator: boolean): Billing {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalSubAmount': json['totalSubAmount'],
        'totalLiquidationPrice': json['totalLiquidationPrice'],
        'discountAmount': json['discountAmount'],
        'totalExtras': !exists(json, 'totalExtras') ? undefined : json['totalExtras'],
        'totalAmount': json['totalAmount'],
        'cardFee': BillingCardFeeFromJSON(json['cardFee']),
        'cardFeeAmount': json['cardFeeAmount'],
        'totalPrice': json['totalPrice'],
        'ages': ((json['ages'] as Array<any>).map(AgeBillingFromJSON)),
        'host': !exists(json, 'host') ? undefined : HostBillingFromJSON(json['host']),
    };
}

export function BillingToJSON(value?: Billing | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalSubAmount': value.totalSubAmount,
        'totalLiquidationPrice': value.totalLiquidationPrice,
        'discountAmount': value.discountAmount,
        'totalExtras': value.totalExtras,
        'totalAmount': value.totalAmount,
        'cardFee': BillingCardFeeToJSON(value.cardFee),
        'cardFeeAmount': value.cardFeeAmount,
        'totalPrice': value.totalPrice,
        'ages': ((value.ages as Array<any>).map(AgeBillingToJSON)),
        'host': HostBillingToJSON(value.host),
    };
}

