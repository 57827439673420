import React, { useState } from "react";
import styled, { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  faListCheck,
} from "@fortawesome/pro-solid-svg-icons";

interface Props {
  label: string;
  children: JSX.Element[] | JSX.Element;
}

interface MenuGroupItems {
  display: string;
}

export const MenuItemsGroup = ({ label, children }: Props): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onChangeArrow = () => setIsOpen(!isOpen);

  return (
    <MenuGroupItemsStyled display={isOpen.toString()}>
      <div onClick={onChangeArrow} className="menu-group-items--title">
        <span>
          <FontAwesomeIcon icon={faListCheck} fixedWidth size="lg" />
        </span>
        {label}
        <FontAwesomeIcon
          style={{ paddingLeft: "2rem" }}
          icon={isOpen ? faChevronUp : faChevronDown}
        />
      </div>
      <div className="menu-group-items--content">{children}</div>
    </MenuGroupItemsStyled>
  );
};

const MenuGroupItemsStyled = styled.div<MenuGroupItems>`
  margin: 0.5rem 0.8rem;

  ${({ theme, display }) => css`
    .menu-group-items {
      &--title {
        display: flex;
        cursor: pointer;
        align-items: center;
        width: auto;
        height: 45px;
        padding: 0 1rem;
        border: none;
        box-sizing: border-box;
        border-radius: ${theme.border_radius.xxx_large};
        color: ${theme.colors.white};

        span {
          margin-right: 1rem;
          display: block;
        }

        &:hover {
          transition: all ease-in-out 0.2s;
          background: ${theme.colors.primary};
          color: #000;
        }
      }

      &--content {
        display: ${display === "true" ? "block" : "none"};
        transition: display 20s ease-out;
      }
    }
  `}
`;
