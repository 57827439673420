import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ScrollTop } from "./ScrollTop";
import {
  AntdInitializer,
  GoogleAnalyticsProvider,
  HeaderMetaInitializer,
  LanguageProvider,
  MomentInitializer,
  StyleThemeInitializer,
  ThemeProvider,
  YupInitializer,
} from "./providers";

ReactDOM.render(
  <ThemeProvider>
    <GoogleAnalyticsProvider>
      <StyleThemeInitializer>
        <BrowserRouter>
          <LanguageProvider>
            <YupInitializer />
            <HeaderMetaInitializer />
            <MomentInitializer />
            <AntdInitializer>
              <ScrollTop>
                <App />
              </ScrollTop>
            </AntdInitializer>
          </LanguageProvider>
        </BrowserRouter>
      </StyleThemeInitializer>
    </GoogleAnalyticsProvider>
  </ThemeProvider>,
  document.getElementById("root")
);
