import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { auth } from "../firebase";
import { notification, Spinner } from "../components";
import { chain, isError } from "lodash";
import { roles } from "../data-list";
import { useCollectionData, useDocumentData } from "../hooks/firebase";
import assert from "assert";
import {
  browserLocalPersistence,
  onAuthStateChanged,
  setPersistence,
  signInWithEmailAndPassword,
  User as FirebaseUser,
} from "firebase/auth";

interface Context {
  authUser: AuthUser | null;
  login: (email: string, password: string) => Promise<void>;
  loginLoading: boolean;
  logout: () => Promise<void>;
}

const AuthenticationContext = createContext<Context>({
  authUser: null,
  login: () => Promise.reject("Unable to find AuthenticationProvider."),
  logout: () => Promise.reject("Unable to find AuthenticationProvider."),
  loginLoading: false,
});

interface Props {
  children: JSX.Element;
}

export const useAuthentication = (): Context =>
  useContext(AuthenticationContext);

export const AuthenticationProvider = ({ children }: Props): JSX.Element => {
  const [authenticating, setAuthenticating] = useState<boolean>(true);
  const [loginLoading, setLoginLoading] = useState<boolean>(false);
  const [firebaseUser, setFirebaseUser] = useState<FirebaseUser | null>(null);
  const [user, setUser] = useState<User | null>(null);

  const [userSnapshot, userLoading, userError] = useDocumentData<User>(
    "users",
    firebaseUser?.uid
  );

  const [roleAcls, , roleAclsError] = useDocumentData<RoleAcls>(
    "roles-acls",
    user?.roleCode
  );

  const [operators, , operatorsError] = useCollectionData<Operator>(
    "onSnapshot",
    "operators",
    [],
    [user?.id],
    !user?.id
  );

  useMemo(() => {
    onAuthStateChanged(auth, (fbUser) =>
      fbUser ? setFirebaseUser(fbUser) : onLogout()
    );
  }, []);

  const error = userError || roleAclsError || operatorsError;

  useEffect(() => {
    error && notification({ type: "error" });
  }, [error]);

  useEffect(() => {
    !userLoading && userSnapshot && onLogin(userSnapshot);
  }, [userLoading, userSnapshot?.id]);

  const onLogin = async (user: User) => {
    setUser(user);
    setLoginLoading(false);
    setAuthenticating(false);
  };

  const onLogout = async () => {
    setAuthenticating(true);
    setUser(null);
    setFirebaseUser(null);
    setAuthenticating(false);
    setLoginLoading(false);
  };

  const login: Context["login"] = async (email, password) => {
    try {
      setLoginLoading(true);

      await setPersistence(auth, browserLocalPersistence);

      await signInWithEmailAndPassword(auth, email, password);
    } catch (e) {
      const error = isError(e) ? e : undefined;

      notification({
        type: "error",
        title: "Login error",
        description: error?.message,
      });

      setLoginLoading(false);
    }
  };

  const logout: Context["logout"] = async () => {
    sessionStorage.clear();
    localStorage.clear();
    await auth.signOut();
  };

  if (authenticating) return <Spinner fullscreen />;

  return (
    <AuthenticationContext.Provider
      value={{
        authUser: user ? mapAuthUser(user, operators, roleAcls) : null,
        login,
        logout,
        loginLoading,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};

const mapAuthUser = (
  user: User,
  operators: Operator[],
  roleAcls?: RoleAcls
): AuthUser => {
  const _operators = operatorsByUser(user, operators);
  const role = roleByUser(user);
  assert(role, "role does not exist!");

  return {
    ...user,
    role,
    acls: roleAcls?.acls || [],
    operators: _operators,
    initialOperator: _operators[0],
  };
};

const roleByUser = (user: User): Role | undefined =>
  roles.find((role) => role.code === user.roleCode);

const operatorsByUser = (user: User, operators: Operator[]): Operator[] =>
  chain(operators)
    .filter((operator) =>
      user.roleCode === "administrator"
        ? true
        : user.operatorsIds.includes(operator.id)
    )
    .orderBy((operator) => operator.name, ["asc"])
    .value();
