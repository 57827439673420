import React, { useEffect } from "react";
import "moment-timezone";
import moment from "moment";
import "moment/locale/es";
import { useLanguage } from "./LanguageProvider";

export const MomentInitializer = (): React.ReactElement => {
  const { currentLanguageCode } = useLanguage();

  moment.updateLocale(currentLanguageCode, {
    week: {
      dow: 1,
    },
  });

  useEffect(() => {
    moment.locale(currentLanguageCode);
  }, [currentLanguageCode]);

  return <React.Fragment />;
};
