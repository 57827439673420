import React, { useState } from "react";
import styled from "styled-components";
import { mediaQuery } from "../../../styles";
import {
  faArrowUpRightFromSquare,
  faIndent,
} from "@fortawesome/pro-solid-svg-icons";
import { PrivatePageMenu } from "./Private.PageMenu";
import { IconAction } from "../../IconAction";
import { Col, Layout, Row } from "antd";
import { PrivateOperatorSelector } from "./Private.OperatorSelector";
import {
  useAuthentication,
  useLanguage,
  useOffice,
  useOperator,
} from "../../../providers";
import { PrivateLanguageSelector } from "./Private.LanguageSelector";
import { Link, useNavigate } from "react-router-dom";
import { Favicon } from "../../../images";
import { useDevice } from "../../../hooks";
import { pathnameWithLanguage } from "../../../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Acl } from "../../Acl";

interface Props {
  showOperatorSelector?: boolean;
}

export const PrivateHeader = ({ showOperatorSelector }: Props): JSX.Element => {
  const { authUser } = useAuthentication();
  const navigate = useNavigate();
  const { isMobile } = useDevice();
  const { currentLanguageCode } = useLanguage();
  const { currentOffice } = useOffice();
  const { currentOperator } = useOperator();

  const [isVisibleMenu, setIsVisibleMenu] = useState(false);

  const title = () => {
    const userEmail = authUser?.email || "";
    const userRoleName = authUser?.role.name || "";
    const agencyName = authUser?.agency?.name || "";
    const officeName = currentOffice?.name || "";

    if (isMobile) return userEmail;

    const emailAndRole = userEmail + " / " + userRoleName;
    const agencyAndOffice = " / " + agencyName + " / " + officeName;

    return currentOffice ? emailAndRole + agencyAndOffice : emailAndRole;
  };

  const onClickHome = () =>
    navigate(pathnameWithLanguage(currentLanguageCode, "/"));

  const operatorPageRegex = /.*\/operators\/.*/;

  const isOperatorPage = operatorPageRegex.test(location.pathname);

  return (
    <>
      <PrivatePageMenu
        setIsVisibleMenu={setIsVisibleMenu}
        isVisibleMenu={isVisibleMenu}
      />
      <ContainerHeader>
        <Row align="middle" wrap={false} gutter={[16, 16]}>
          <Col flex="none" span={2}>
            <IconAction
              data-testid="drawer"
              onClick={() => setIsVisibleMenu(true)}
              icon={faIndent}
            />
          </Col>
          {!isMobile && (
            <Col>
              <img
                src={Favicon}
                onClick={() => onClickHome()}
                alt="icon"
                className="isotipo-item"
              />
            </Col>
          )}
          <Col flex={2}>
            <TitleStyled>{title()}</TitleStyled>
          </Col>
          <Col flex={7}>
            <FlexWrapper>
              <PrivateLanguageSelector />
              {isOperatorPage && showOperatorSelector && (
                <>
                  <PrivateOperatorSelector />
                  <Acl aclId="linkToOperatorInHeader">
                    <Link
                      to={`/${currentLanguageCode}/u/operators/${currentOperator?.id}`}
                    >
                      <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                    </Link>
                  </Acl>
                </>
              )}
            </FlexWrapper>
          </Col>
        </Row>
      </ContainerHeader>
    </>
  );
};

const FlexWrapper = styled.div`
  display: flex;
  gap: 0.5em;
  justify-content: end;
  align-items: center;
  width: 100%;
`;

export const ContainerHeader = styled(Layout.Header)`
  border-bottom: 1px solid rgb(232, 232, 232);
  padding: 0 15px 0 5px !important;
  height: 3rem;
  @media print {
    display: none;
  }

  .isotipo-item {
    width: 2.5em;
    height: auto;
    object-fit: contain;
    cursor: pointer;
  }

  ${mediaQuery.minTablet} {
    height: 3.5rem;
  }

  .ant-row {
    height: 100%;
  }
`;

const TitleStyled = styled.div`
  font-size: ${({ theme }) => theme.font_sizes.x_small};
  line-height: 1.2;
  padding: 5px;

  ${mediaQuery.minTablet} {
    font-size: ${({ theme }) => theme.font_sizes.large};
    line-height: normal;
  }
`;
