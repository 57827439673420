import React, { createContext, useContext, useState } from "react";
import { useAuthentication } from "./AuthenticationProvider";
import { useLanguage } from "./LanguageProvider";
import { useLocation, useNavigate } from "react-router-dom";
import UrlAssembler from "url-assembler";
import { useGlobalData } from "./GlobalDataProvider";

interface Context {
  currentOperator: CurrentOperator | null;
  onChangeOperator: (operatorId: string) => void;
  onNavigateInOperator: (location: string, operatorId?: string) => void;
}

const OperatorContext = createContext<Context>({
  currentOperator: null,
  onChangeOperator: (operatorId) => console.info(operatorId),
  onNavigateInOperator: () => console.info(),
});

interface Props {
  children: JSX.Element;
}

export const OperatorProvider = ({ children }: Props): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();

  const { authUser } = useAuthentication();
  const { operators } = useGlobalData();
  const { currentLanguageCode } = useLanguage();

  const operatorFromUrl =
    authUser && findOperatorFromUrl(location.pathname, authUser.operators);

  const [operator, setOperator] = useState<Operator | null>(
    operatorFromUrl || authUser?.initialOperator || null
  );

  const onChangeOperator: Context["onChangeOperator"] = (operatorId) => {
    const operator = operators.find((operator) => operator.id === operatorId);

    operator && setOperator(operator);
  };

  const onNavigateInOperator: Context["onNavigateInOperator"] = (
    pathname,
    operatorId
  ) => {
    const basePathname = new UrlAssembler()
      .template("/:languageCode/u/operators/:operatorId")
      .param({
        languageCode: currentLanguageCode,
        operatorId: operatorId || operator?.id,
      })
      .toString();

    navigate(basePathname + pathname);

    operatorId && onChangeOperator(operatorId);
  };

  return (
    <OperatorContext.Provider
      value={{
        currentOperator: operator ? mapCurrentOperator(operator) : null,
        onChangeOperator,
        onNavigateInOperator,
      }}
    >
      {children}
    </OperatorContext.Provider>
  );
};
export const useOperator = (): Context => useContext(OperatorContext);

const mapCurrentOperator = (operator: Operator): CurrentOperator => ({
  ...operator,
});

const findOperatorFromUrl = (
  pathname: string,
  operators: Operator[]
): Operator | undefined => {
  const operatorPageRegex = /.*\/operators\/.*/;
  const isOperatorPagePrivate = operatorPageRegex.test(location.pathname);
  const [, , operatorIdPublic, operatorIdPrivate] = pathname
    .slice(1)
    .split("/");
  const operatorId = isOperatorPagePrivate
    ? operatorIdPrivate
    : operatorIdPublic;

  return operators.find((operator) => operator.id === operatorId);
};
