/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AgeId } from './AgeId';
import {
    AgeIdFromJSON,
    AgeIdFromJSONTyped,
    AgeIdToJSON,
} from './AgeId';
import type { Languages } from './Languages';
import {
    LanguagesFromJSON,
    LanguagesFromJSONTyped,
    LanguagesToJSON,
} from './Languages';

/**
 * 
 * @export
 * @interface AgeBilling
 */
export interface AgeBilling {
    /**
     * 
     * @type {AgeId}
     * @memberof AgeBilling
     */
    ageId: AgeId;
    /**
     * 
     * @type {Languages}
     * @memberof AgeBilling
     */
    name: Languages;
    /**
     * 
     * @type {string}
     * @memberof AgeBilling
     */
    suggestedPrice: string;
    /**
     * 
     * @type {number}
     * @memberof AgeBilling
     */
    quantity: number;
    /**
     * 
     * @type {string}
     * @memberof AgeBilling
     */
    subTotal: string;
    /**
     * 
     * @type {string}
     * @memberof AgeBilling
     */
    confidentialPrice: string;
}

/**
 * Check if a given object implements the AgeBilling interface.
 */
export function instanceOfAgeBilling(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "ageId" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "suggestedPrice" in value;
    isInstance = isInstance && "quantity" in value;
    isInstance = isInstance && "subTotal" in value;
    isInstance = isInstance && "confidentialPrice" in value;

    return isInstance;
}

export function AgeBillingFromJSON(json: any): AgeBilling {
    return AgeBillingFromJSONTyped(json, false);
}

export function AgeBillingFromJSONTyped(json: any, ignoreDiscriminator: boolean): AgeBilling {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ageId': AgeIdFromJSON(json['ageId']),
        'name': LanguagesFromJSON(json['name']),
        'suggestedPrice': json['suggestedPrice'],
        'quantity': json['quantity'],
        'subTotal': json['subTotal'],
        'confidentialPrice': json['confidentialPrice'],
    };
}

export function AgeBillingToJSON(value?: AgeBilling | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ageId': AgeIdToJSON(value.ageId),
        'name': LanguagesToJSON(value.name),
        'suggestedPrice': value.suggestedPrice,
        'quantity': value.quantity,
        'subTotal': value.subTotal,
        'confidentialPrice': value.confidentialPrice,
    };
}

