import { notification } from "../components";
import { isObject } from "lodash";
import { ApiError } from "../openapi";

export const getApiErrorResponse = async (
  response: unknown
): Promise<unknown> => {
  try {
    if (isResponse(response)) {
      const responseText = await response.text();
      try {
        return JSON.parse(responseText);
      } catch (e) {
        return responseText;
      }
    }
  } catch (e) {
    return;
  }
};

export const apiErrorNotification = (
  translate: Translate,
  response: unknown
): void => {
  isApiError(response)
    ? notificationApiError(translate, response)
    : notification({ type: "error" });
};

const notificationApiError = (
  translate: Translate,
  apiError: ApiError
): void => {
  const titleDefault = translate(`api_code.default.title`);
  const descriptionDefault = translate(`api_code.default.description`);

  notification({
    type: "warning",
    duration: 10,
    title: translate(
      `api_code.${apiError.key}.title`,
      apiError.params,
      titleDefault
    ),
    description: translate(
      `api_code.${apiError.key}.description`,
      apiError.params,
      descriptionDefault
    ),
  });
};

export const isApiError = (data: unknown): data is ApiError =>
  isObject(data) && "isApiError" in data;

export const isResponse = (data: unknown): data is Response =>
  isObject(data) && "text" in data;
