/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PostReservationBodyContact } from './PostReservationBodyContact';
import {
    PostReservationBodyContactFromJSON,
    PostReservationBodyContactFromJSONTyped,
    PostReservationBodyContactToJSON,
} from './PostReservationBodyContact';
import type { Traveler } from './Traveler';
import {
    TravelerFromJSON,
    TravelerFromJSONTyped,
    TravelerToJSON,
} from './Traveler';

/**
 * 
 * @export
 * @interface PutTravelersBody
 */
export interface PutTravelersBody {
    /**
     * 
     * @type {Array<Traveler>}
     * @memberof PutTravelersBody
     */
    travelers: Array<Traveler>;
    /**
     * 
     * @type {PostReservationBodyContact}
     * @memberof PutTravelersBody
     */
    contact: PostReservationBodyContact | null;
}

/**
 * Check if a given object implements the PutTravelersBody interface.
 */
export function instanceOfPutTravelersBody(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "travelers" in value;
    isInstance = isInstance && "contact" in value;

    return isInstance;
}

export function PutTravelersBodyFromJSON(json: any): PutTravelersBody {
    return PutTravelersBodyFromJSONTyped(json, false);
}

export function PutTravelersBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutTravelersBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'travelers': ((json['travelers'] as Array<any>).map(TravelerFromJSON)),
        'contact': PostReservationBodyContactFromJSON(json['contact']),
    };
}

export function PutTravelersBodyToJSON(value?: PutTravelersBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'travelers': ((value.travelers as Array<any>).map(TravelerToJSON)),
        'contact': PostReservationBodyContactToJSON(value.contact),
    };
}

