/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FirebaseFirestoreTimestamp } from './FirebaseFirestoreTimestamp';
import {
    FirebaseFirestoreTimestampFromJSON,
    FirebaseFirestoreTimestampFromJSONTyped,
    FirebaseFirestoreTimestampToJSON,
} from './FirebaseFirestoreTimestamp';

/**
 * 
 * @export
 * @interface PickIUserExcludeKeyofIUserKeyofPickDefaultFirestorePropsAgency
 */
export interface PickIUserExcludeKeyofIUserKeyofPickDefaultFirestorePropsAgency {
    /**
     * 
     * @type {FirebaseFirestoreTimestamp}
     * @memberof PickIUserExcludeKeyofIUserKeyofPickDefaultFirestorePropsAgency
     */
    createAt: FirebaseFirestoreTimestamp;
    /**
     * 
     * @type {FirebaseFirestoreTimestamp}
     * @memberof PickIUserExcludeKeyofIUserKeyofPickDefaultFirestorePropsAgency
     */
    updateAt: FirebaseFirestoreTimestamp;
    /**
     * 
     * @type {string}
     * @memberof PickIUserExcludeKeyofIUserKeyofPickDefaultFirestorePropsAgency
     */
    updateBy: string;
    /**
     * 
     * @type {boolean}
     * @memberof PickIUserExcludeKeyofIUserKeyofPickDefaultFirestorePropsAgency
     */
    isDeleted: boolean;
    /**
     * 
     * @type {string}
     * @memberof PickIUserExcludeKeyofIUserKeyofPickDefaultFirestorePropsAgency
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PickIUserExcludeKeyofIUserKeyofPickDefaultFirestorePropsAgency
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof PickIUserExcludeKeyofIUserKeyofPickDefaultFirestorePropsAgency
     */
    sendEmailOnReservationBooked: boolean;
}

/**
 * Check if a given object implements the PickIUserExcludeKeyofIUserKeyofPickDefaultFirestorePropsAgency interface.
 */
export function instanceOfPickIUserExcludeKeyofIUserKeyofPickDefaultFirestorePropsAgency(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "createAt" in value;
    isInstance = isInstance && "updateAt" in value;
    isInstance = isInstance && "updateBy" in value;
    isInstance = isInstance && "isDeleted" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "sendEmailOnReservationBooked" in value;

    return isInstance;
}

export function PickIUserExcludeKeyofIUserKeyofPickDefaultFirestorePropsAgencyFromJSON(json: any): PickIUserExcludeKeyofIUserKeyofPickDefaultFirestorePropsAgency {
    return PickIUserExcludeKeyofIUserKeyofPickDefaultFirestorePropsAgencyFromJSONTyped(json, false);
}

export function PickIUserExcludeKeyofIUserKeyofPickDefaultFirestorePropsAgencyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PickIUserExcludeKeyofIUserKeyofPickDefaultFirestorePropsAgency {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createAt': FirebaseFirestoreTimestampFromJSON(json['createAt']),
        'updateAt': FirebaseFirestoreTimestampFromJSON(json['updateAt']),
        'updateBy': json['updateBy'],
        'isDeleted': json['isDeleted'],
        'id': json['id'],
        'name': json['name'],
        'sendEmailOnReservationBooked': json['sendEmailOnReservationBooked'],
    };
}

export function PickIUserExcludeKeyofIUserKeyofPickDefaultFirestorePropsAgencyToJSON(value?: PickIUserExcludeKeyofIUserKeyofPickDefaultFirestorePropsAgency | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createAt': FirebaseFirestoreTimestampToJSON(value.createAt),
        'updateAt': FirebaseFirestoreTimestampToJSON(value.updateAt),
        'updateBy': value.updateBy,
        'isDeleted': value.isDeleted,
        'id': value.id,
        'name': value.name,
        'sendEmailOnReservationBooked': value.sendEmailOnReservationBooked,
    };
}

