const loadTranslation = async (languageCode: string) => {
  switch (languageCode) {
    case "en":
      return (await import("./en")).default;
    case "es":
      return (await import("./es")).default;
    case "pt":
      return (await import("./pt")).default;
    default:
      return {};
  }
};

export { loadTranslation };
