/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TourDetailsDay } from './TourDetailsDay';
import {
    TourDetailsDayFromJSON,
    TourDetailsDayFromJSONTyped,
    TourDetailsDayToJSON,
} from './TourDetailsDay';

/**
 * 
 * @export
 * @interface TourDetails
 */
export interface TourDetails {
    /**
     * 
     * @type {Array<TourDetailsDay>}
     * @memberof TourDetails
     */
    days: Array<TourDetailsDay>;
}

/**
 * Check if a given object implements the TourDetails interface.
 */
export function instanceOfTourDetails(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "days" in value;

    return isInstance;
}

export function TourDetailsFromJSON(json: any): TourDetails {
    return TourDetailsFromJSONTyped(json, false);
}

export function TourDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): TourDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'days': ((json['days'] as Array<any>).map(TourDetailsDayFromJSON)),
    };
}

export function TourDetailsToJSON(value?: TourDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'days': ((value.days as Array<any>).map(TourDetailsDayToJSON)),
    };
}

