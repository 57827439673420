/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Tier
 */
export interface Tier {
    /**
     * 
     * @type {number}
     * @memberof Tier
     */
    minTravelers: number | null;
    /**
     * 
     * @type {number}
     * @memberof Tier
     */
    maxTravelers: number | null;
    /**
     * 
     * @type {string}
     * @memberof Tier
     */
    suggestedPrice: string;
    /**
     * 
     * @type {string}
     * @memberof Tier
     */
    confidentialPrice: string;
    /**
     * 
     * @type {string}
     * @memberof Tier
     */
    confidentialPriceCurrencyCode: string;
    /**
     * 
     * @type {string}
     * @memberof Tier
     */
    noShowPrice: string | null;
}

/**
 * Check if a given object implements the Tier interface.
 */
export function instanceOfTier(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "minTravelers" in value;
    isInstance = isInstance && "maxTravelers" in value;
    isInstance = isInstance && "suggestedPrice" in value;
    isInstance = isInstance && "confidentialPrice" in value;
    isInstance = isInstance && "confidentialPriceCurrencyCode" in value;
    isInstance = isInstance && "noShowPrice" in value;

    return isInstance;
}

export function TierFromJSON(json: any): Tier {
    return TierFromJSONTyped(json, false);
}

export function TierFromJSONTyped(json: any, ignoreDiscriminator: boolean): Tier {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'minTravelers': json['minTravelers'],
        'maxTravelers': json['maxTravelers'],
        'suggestedPrice': json['suggestedPrice'],
        'confidentialPrice': json['confidentialPrice'],
        'confidentialPriceCurrencyCode': json['confidentialPriceCurrencyCode'],
        'noShowPrice': json['noShowPrice'],
    };
}

export function TierToJSON(value?: Tier | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'minTravelers': value.minTravelers,
        'maxTravelers': value.maxTravelers,
        'suggestedPrice': value.suggestedPrice,
        'confidentialPrice': value.confidentialPrice,
        'confidentialPriceCurrencyCode': value.confidentialPriceCurrencyCode,
        'noShowPrice': value.noShowPrice,
    };
}

