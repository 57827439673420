/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DiscountType } from './DiscountType';
import {
    DiscountTypeFromJSON,
    DiscountTypeFromJSONTyped,
    DiscountTypeToJSON,
} from './DiscountType';
import type { FirebaseFirestoreTimestamp } from './FirebaseFirestoreTimestamp';
import {
    FirebaseFirestoreTimestampFromJSON,
    FirebaseFirestoreTimestampFromJSONTyped,
    FirebaseFirestoreTimestampToJSON,
} from './FirebaseFirestoreTimestamp';

/**
 * 
 * @export
 * @interface ReservationDiscount
 */
export interface ReservationDiscount {
    /**
     * 
     * @type {FirebaseFirestoreTimestamp}
     * @memberof ReservationDiscount
     */
    createAt: FirebaseFirestoreTimestamp;
    /**
     * 
     * @type {FirebaseFirestoreTimestamp}
     * @memberof ReservationDiscount
     */
    updateAt: FirebaseFirestoreTimestamp;
    /**
     * 
     * @type {string}
     * @memberof ReservationDiscount
     */
    updateBy: string;
    /**
     * 
     * @type {boolean}
     * @memberof ReservationDiscount
     */
    isDeleted: boolean;
    /**
     * 
     * @type {string}
     * @memberof ReservationDiscount
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ReservationDiscount
     */
    code: string;
    /**
     * 
     * @type {number}
     * @memberof ReservationDiscount
     */
    minTravelers?: number;
    /**
     * 
     * @type {DiscountType}
     * @memberof ReservationDiscount
     */
    type: DiscountType;
    /**
     * 
     * @type {string}
     * @memberof ReservationDiscount
     */
    value: string;
    /**
     * 
     * @type {FirebaseFirestoreTimestamp}
     * @memberof ReservationDiscount
     */
    expireAt: FirebaseFirestoreTimestamp;
    /**
     * 
     * @type {boolean}
     * @memberof ReservationDiscount
     */
    active: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReservationDiscount
     */
    operatorsIds: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReservationDiscount
     */
    toursIds: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReservationDiscount
     */
    toursOptionsIds: Array<string>;
}

/**
 * Check if a given object implements the ReservationDiscount interface.
 */
export function instanceOfReservationDiscount(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "createAt" in value;
    isInstance = isInstance && "updateAt" in value;
    isInstance = isInstance && "updateBy" in value;
    isInstance = isInstance && "isDeleted" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "code" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "value" in value;
    isInstance = isInstance && "expireAt" in value;
    isInstance = isInstance && "active" in value;
    isInstance = isInstance && "operatorsIds" in value;
    isInstance = isInstance && "toursIds" in value;
    isInstance = isInstance && "toursOptionsIds" in value;

    return isInstance;
}

export function ReservationDiscountFromJSON(json: any): ReservationDiscount {
    return ReservationDiscountFromJSONTyped(json, false);
}

export function ReservationDiscountFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReservationDiscount {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createAt': FirebaseFirestoreTimestampFromJSON(json['createAt']),
        'updateAt': FirebaseFirestoreTimestampFromJSON(json['updateAt']),
        'updateBy': json['updateBy'],
        'isDeleted': json['isDeleted'],
        'id': json['id'],
        'code': json['code'],
        'minTravelers': !exists(json, 'minTravelers') ? undefined : json['minTravelers'],
        'type': DiscountTypeFromJSON(json['type']),
        'value': json['value'],
        'expireAt': FirebaseFirestoreTimestampFromJSON(json['expireAt']),
        'active': json['active'],
        'operatorsIds': json['operatorsIds'],
        'toursIds': json['toursIds'],
        'toursOptionsIds': json['toursOptionsIds'],
    };
}

export function ReservationDiscountToJSON(value?: ReservationDiscount | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createAt': FirebaseFirestoreTimestampToJSON(value.createAt),
        'updateAt': FirebaseFirestoreTimestampToJSON(value.updateAt),
        'updateBy': value.updateBy,
        'isDeleted': value.isDeleted,
        'id': value.id,
        'code': value.code,
        'minTravelers': value.minTravelers,
        'type': DiscountTypeToJSON(value.type),
        'value': value.value,
        'expireAt': FirebaseFirestoreTimestampToJSON(value.expireAt),
        'active': value.active,
        'operatorsIds': value.operatorsIds,
        'toursIds': value.toursIds,
        'toursOptionsIds': value.toursOptionsIds,
    };
}

