/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OpenpayTokenCardAddress } from './OpenpayTokenCardAddress';
import {
    OpenpayTokenCardAddressFromJSON,
    OpenpayTokenCardAddressFromJSONTyped,
    OpenpayTokenCardAddressToJSON,
} from './OpenpayTokenCardAddress';

/**
 * 
 * @export
 * @interface OpenpayTokenCard
 */
export interface OpenpayTokenCard {
    /**
     * 
     * @type {boolean}
     * @memberof OpenpayTokenCard
     */
    pointsCard: boolean;
    /**
     * 
     * @type {string}
     * @memberof OpenpayTokenCard
     */
    brand: string;
    /**
     * 
     * @type {string}
     * @memberof OpenpayTokenCard
     */
    creationDate: string | null;
    /**
     * 
     * @type {OpenpayTokenCardAddress}
     * @memberof OpenpayTokenCard
     */
    address: OpenpayTokenCardAddress;
    /**
     * 
     * @type {string}
     * @memberof OpenpayTokenCard
     */
    expirationMonth: string;
    /**
     * 
     * @type {string}
     * @memberof OpenpayTokenCard
     */
    expirationYear: string;
    /**
     * 
     * @type {string}
     * @memberof OpenpayTokenCard
     */
    holderName: string;
    /**
     * 
     * @type {string}
     * @memberof OpenpayTokenCard
     */
    cardNumber: string;
}

/**
 * Check if a given object implements the OpenpayTokenCard interface.
 */
export function instanceOfOpenpayTokenCard(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "pointsCard" in value;
    isInstance = isInstance && "brand" in value;
    isInstance = isInstance && "creationDate" in value;
    isInstance = isInstance && "address" in value;
    isInstance = isInstance && "expirationMonth" in value;
    isInstance = isInstance && "expirationYear" in value;
    isInstance = isInstance && "holderName" in value;
    isInstance = isInstance && "cardNumber" in value;

    return isInstance;
}

export function OpenpayTokenCardFromJSON(json: any): OpenpayTokenCard {
    return OpenpayTokenCardFromJSONTyped(json, false);
}

export function OpenpayTokenCardFromJSONTyped(json: any, ignoreDiscriminator: boolean): OpenpayTokenCard {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pointsCard': json['points_card'],
        'brand': json['brand'],
        'creationDate': json['creation_date'],
        'address': OpenpayTokenCardAddressFromJSON(json['address']),
        'expirationMonth': json['expiration_month'],
        'expirationYear': json['expiration_year'],
        'holderName': json['holder_name'],
        'cardNumber': json['card_number'],
    };
}

export function OpenpayTokenCardToJSON(value?: OpenpayTokenCard | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'points_card': value.pointsCard,
        'brand': value.brand,
        'creation_date': value.creationDate,
        'address': OpenpayTokenCardAddressToJSON(value.address),
        'expiration_month': value.expirationMonth,
        'expiration_year': value.expirationYear,
        'holder_name': value.holderName,
        'card_number': value.cardNumber,
    };
}

