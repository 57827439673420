import React, { createContext, useContext } from "react";
import { useQueriesState } from "../hooks";
import moment from "moment/moment";
import { currentConfig } from "../config";

type Context = { utm: Utm | null };

interface UtmStorage extends Utm {
  timestamp: Date;
}

interface UrlQuery {
  utm_campaign?: string | null;
  utm_source?: string | null;
}

interface Props {
  children: JSX.Element;
}

export const UTMContext = createContext<Context>({ utm: null });

export function UTMProvider({ children }: Props): JSX.Element {
  const [{ utm_campaign, utm_source }] = useQueriesState<UrlQuery>({
    utm_source: undefined,
    utm_campaign: undefined,
  });

  let utm: Utm | null =
    utm_source || utm_campaign
      ? { campaign: utm_campaign || null, source: utm_source || null }
      : null;

  if (utm) {
    setUtmStorage({
      source: utm.source,
      campaign: utm.campaign,
      timestamp: new Date(),
    });
  } else {
    const utmStorage = getUtmStorage();

    if (utmStorage) utm = utmStorage;
  }

  console.log("UTM", utm);

  return <UTMContext.Provider value={{ utm }}>{children}</UTMContext.Provider>;
}

const setUtmStorage = (utm: UtmStorage): void =>
  localStorage.setItem("utm", JSON.stringify(utm));

const getUtmStorage = (): Utm | null => {
  const utmStorage = localStorage.getItem("utm");

  if (!utmStorage) return null;

  const { campaign, source, timestamp } = JSON.parse(utmStorage) as UtmStorage;

  const hasNotExpired =
    moment().diff(moment(timestamp), "hours", true) <
    currentConfig.utmExpirationHours;

  if (!hasNotExpired) {
    localStorage.removeItem("utm");
    return null;
  }

  return { campaign, source };
};

export const useUTM = (): Context => useContext(UTMContext);
