/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Pickup } from './Pickup';
import {
    PickupFromJSON,
    PickupFromJSONTyped,
    PickupToJSON,
} from './Pickup';

/**
 * 
 * @export
 * @interface PostChangeReservationBody
 */
export interface PostChangeReservationBody {
    /**
     * 
     * @type {string}
     * @memberof PostChangeReservationBody
     */
    startDate: string;
    /**
     * 
     * @type {string}
     * @memberof PostChangeReservationBody
     */
    optionId: string;
    /**
     * 
     * @type {string}
     * @memberof PostChangeReservationBody
     */
    time: string;
    /**
     * 
     * @type {Pickup}
     * @memberof PostChangeReservationBody
     */
    pickup: Pickup;
    /**
     * 
     * @type {string}
     * @memberof PostChangeReservationBody
     */
    tourId: string;
    /**
     * 
     * @type {boolean}
     * @memberof PostChangeReservationBody
     */
    sendEmail: boolean;
}

/**
 * Check if a given object implements the PostChangeReservationBody interface.
 */
export function instanceOfPostChangeReservationBody(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "startDate" in value;
    isInstance = isInstance && "optionId" in value;
    isInstance = isInstance && "time" in value;
    isInstance = isInstance && "pickup" in value;
    isInstance = isInstance && "tourId" in value;
    isInstance = isInstance && "sendEmail" in value;

    return isInstance;
}

export function PostChangeReservationBodyFromJSON(json: any): PostChangeReservationBody {
    return PostChangeReservationBodyFromJSONTyped(json, false);
}

export function PostChangeReservationBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostChangeReservationBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'startDate': json['startDate'],
        'optionId': json['optionId'],
        'time': json['time'],
        'pickup': PickupFromJSON(json['pickup']),
        'tourId': json['tourId'],
        'sendEmail': json['sendEmail'],
    };
}

export function PostChangeReservationBodyToJSON(value?: PostChangeReservationBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'startDate': value.startDate,
        'optionId': value.optionId,
        'time': value.time,
        'pickup': PickupToJSON(value.pickup),
        'tourId': value.tourId,
        'sendEmail': value.sendEmail,
    };
}

