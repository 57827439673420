import React from "react";
import styled, { css } from "styled-components";
import { mediaQuery } from "../styles";

interface Props extends ContainerProps {
  children: React.ReactNode;
}

export const WrapperComponent = ({
  children,
  padding = "0 0.8rem",
  paddingMb = ".8em",
}: Props): JSX.Element => (
  <Container padding={padding} paddingMb={paddingMb}>
    {children}
  </Container>
);

interface ContainerProps {
  padding?: string;
  paddingMb?: string;
}

const Container = styled.div<ContainerProps>`
  ${({ padding, paddingMb }) => css`
    position: relative;
    width: 100%;
    height: 100%;
    overflow: initial;
    box-sizing: border-box;
    max-width: 100%;
    margin: auto;
    padding: ${paddingMb};
    ${mediaQuery.minTablet} {
      max-width: 95%;
      padding: ${padding};
      width: 84%;
    }

    @media (min-width: 1300px) {
      width: 75%;
    }

    .hr-line {
      opacity: 0.5;
    }

    .help-center-descripcion {
      font-size: 16px;
    }
  `}
`;
