/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Languages } from './Languages';
import {
    LanguagesFromJSON,
    LanguagesFromJSONTyped,
    LanguagesToJSON,
} from './Languages';

/**
 * 
 * @export
 * @interface ExtraGroup
 */
export interface ExtraGroup {
    /**
     * 
     * @type {Languages}
     * @memberof ExtraGroup
     */
    name: Languages;
    /**
     * 
     * @type {string}
     * @memberof ExtraGroup
     */
    id: string;
}

/**
 * Check if a given object implements the ExtraGroup interface.
 */
export function instanceOfExtraGroup(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "id" in value;

    return isInstance;
}

export function ExtraGroupFromJSON(json: any): ExtraGroup {
    return ExtraGroupFromJSONTyped(json, false);
}

export function ExtraGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExtraGroup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': LanguagesFromJSON(json['name']),
        'id': json['id'],
    };
}

export function ExtraGroupToJSON(value?: ExtraGroup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': LanguagesToJSON(value.name),
        'id': value.id,
    };
}

