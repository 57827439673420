/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const AgeId = {
    Adult: 'adult',
    Youth: 'youth',
    Child: 'child',
    Senior: 'senior',
    Student: 'student',
    Infant: 'infant',
    Other1: 'other1',
    Other2: 'other2',
    Other3: 'other3',
    Other4: 'other4',
    Other5: 'other5'
} as const;
export type AgeId = typeof AgeId[keyof typeof AgeId];


export function AgeIdFromJSON(json: any): AgeId {
    return AgeIdFromJSONTyped(json, false);
}

export function AgeIdFromJSONTyped(json: any, ignoreDiscriminator: boolean): AgeId {
    return json as AgeId;
}

export function AgeIdToJSON(value?: AgeId | null): any {
    return value as any;
}

