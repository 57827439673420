import { ES, FlagComponent, US, BR } from "country-flag-icons/react/3x2";

export interface Language {
  code: LanguageCode;
  name: string;
  value: string;
  Flag: FlagComponent;
}

export const languages: Language[] = [
  {
    code: "es",
    name: "Español",
    value: "spanish",
    Flag: ES,
  },
  {
    code: "en",
    name: "English",
    value: "english",
    Flag: US,
  },
  {
    code: "pt",
    name: "Português",
    value: "portuguese",
    Flag: BR,
  },
];
