import React, { createContext, useContext, useEffect } from "react";
import { useDocumentData } from "../hooks/firebase";
import { currentConfig } from "../config";
import { Button, Spinner } from "../components";
import { Result } from "antd";
import styled from "styled-components";
import { doc, onSnapshot } from "firebase/firestore";
import { firestore } from "../firebase";

interface Context {
  version: string;
}

const VersionContext = createContext<Context>({
  version: "",
});

interface Props {
  children: JSX.Element;
}

export const VersionProvider = ({ children }: Props): JSX.Element => {
  const [
    settingDefault,
    settingDefaultLoading,
    settingDefaultError,
  ] = useDocumentData<SettingDefault>("settings", "default");

  useEffect(() => {
    let pageLoaded = false;

    const unsubscribe = onSnapshot(
      doc(firestore, "settings", "default"),
      () => {
        pageLoaded && document.location.reload();
        pageLoaded = true;
      }
    );

    return () => {
      unsubscribe();
    };
  }, []);

  const onClickRefresh = (): void => document.location.reload();

  if (settingDefaultLoading && location.pathname !== "/")
    return <Spinner fullscreen />;

  if (settingDefaultError && location.pathname !== "/")
    return (
      <Result
        status="500"
        title="500"
        subTitle="Sorry, something went wrong."
        extra={
          <Button onClick={onClickRefresh} type="primary">
            REFRESH
          </Button>
        }
      />
    );

  const isLastVersion = currentConfig.version === settingDefault?.version;

  if (!isLastVersion && location.pathname !== "/") return <Version />;

  return (
    <VersionContext.Provider
      value={{
        version: currentConfig.version,
      }}
    >
      {children}
    </VersionContext.Provider>
  );
};

export const useVersion = (): Context => useContext(VersionContext);

export const Version = (): JSX.Element => (
  <VersionContainer>
    <h1>
      Please refresh to get the latest version of the application.
      <br />
    </h1>
    <Button
      type="primary"
      size="large"
      onClick={() => document.location.reload()}
    >
      REFRESH
    </Button>
  </VersionContainer>
);

export const VersionContainer = styled.section`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100%;
  flex-direction: column;
`;
