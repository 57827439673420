/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AgeId } from './AgeId';
import {
    AgeIdFromJSON,
    AgeIdFromJSONTyped,
    AgeIdToJSON,
} from './AgeId';
import type { Languages } from './Languages';
import {
    LanguagesFromJSON,
    LanguagesFromJSONTyped,
    LanguagesToJSON,
} from './Languages';
import type { Tier } from './Tier';
import {
    TierFromJSON,
    TierFromJSONTyped,
    TierToJSON,
} from './Tier';

/**
 * 
 * @export
 * @interface AgeTiers
 */
export interface AgeTiers {
    /**
     * 
     * @type {AgeId}
     * @memberof AgeTiers
     */
    id: AgeId;
    /**
     * 
     * @type {Languages}
     * @memberof AgeTiers
     */
    name: Languages;
    /**
     * 
     * @type {Languages}
     * @memberof AgeTiers
     */
    information?: Languages;
    /**
     * 
     * @type {number}
     * @memberof AgeTiers
     */
    from: number;
    /**
     * 
     * @type {number}
     * @memberof AgeTiers
     */
    to: number;
    /**
     * 
     * @type {boolean}
     * @memberof AgeTiers
     */
    requiresAdult?: boolean;
    /**
     * 
     * @type {Array<Tier>}
     * @memberof AgeTiers
     */
    tiers: Array<Tier>;
}

/**
 * Check if a given object implements the AgeTiers interface.
 */
export function instanceOfAgeTiers(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "from" in value;
    isInstance = isInstance && "to" in value;
    isInstance = isInstance && "tiers" in value;

    return isInstance;
}

export function AgeTiersFromJSON(json: any): AgeTiers {
    return AgeTiersFromJSONTyped(json, false);
}

export function AgeTiersFromJSONTyped(json: any, ignoreDiscriminator: boolean): AgeTiers {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': AgeIdFromJSON(json['id']),
        'name': LanguagesFromJSON(json['name']),
        'information': !exists(json, 'information') ? undefined : LanguagesFromJSON(json['information']),
        'from': json['from'],
        'to': json['to'],
        'requiresAdult': !exists(json, 'requiresAdult') ? undefined : json['requiresAdult'],
        'tiers': ((json['tiers'] as Array<any>).map(TierFromJSON)),
    };
}

export function AgeTiersToJSON(value?: AgeTiers | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': AgeIdToJSON(value.id),
        'name': LanguagesToJSON(value.name),
        'information': LanguagesToJSON(value.information),
        'from': value.from,
        'to': value.to,
        'requiresAdult': value.requiresAdult,
        'tiers': ((value.tiers as Array<any>).map(TierToJSON)),
    };
}

