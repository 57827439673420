/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AgeId } from './AgeId';
import {
    AgeIdFromJSON,
    AgeIdFromJSONTyped,
    AgeIdToJSON,
} from './AgeId';
import type { HubSpot } from './HubSpot';
import {
    HubSpotFromJSON,
    HubSpotFromJSONTyped,
    HubSpotToJSON,
} from './HubSpot';
import type { TravelerPassportImage } from './TravelerPassportImage';
import {
    TravelerPassportImageFromJSON,
    TravelerPassportImageFromJSONTyped,
    TravelerPassportImageToJSON,
} from './TravelerPassportImage';

/**
 * 
 * @export
 * @interface PostReservationBodyTraveler
 */
export interface PostReservationBodyTraveler {
    /**
     * 
     * @type {string}
     * @memberof PostReservationBodyTraveler
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PostReservationBodyTraveler
     */
    firstName: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostReservationBodyTraveler
     */
    lastName: string | null;
    /**
     * 
     * @type {AgeId}
     * @memberof PostReservationBodyTraveler
     */
    ageId: AgeId;
    /**
     * 
     * @type {string}
     * @memberof PostReservationBodyTraveler
     */
    age: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostReservationBodyTraveler
     */
    gender: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostReservationBodyTraveler
     */
    countryCode: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostReservationBodyTraveler
     */
    weight: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostReservationBodyTraveler
     */
    height: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostReservationBodyTraveler
     */
    helmet: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostReservationBodyTraveler
     */
    gloves: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostReservationBodyTraveler
     */
    tShirt: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostReservationBodyTraveler
     */
    dateOfBirth: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostReservationBodyTraveler
     */
    passport: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostReservationBodyTraveler
     */
    flightNumber: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostReservationBodyTraveler
     */
    arrivalTime: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostReservationBodyTraveler
     */
    airline: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostReservationBodyTraveler
     */
    food: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostReservationBodyTraveler
     */
    dietaryRestrictions: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostReservationBodyTraveler
     */
    comment?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PostReservationBodyTraveler
     */
    show: boolean;
    /**
     * 
     * @type {TravelerPassportImage}
     * @memberof PostReservationBodyTraveler
     */
    passportImage?: TravelerPassportImage;
    /**
     * 
     * @type {HubSpot}
     * @memberof PostReservationBodyTraveler
     */
    hubspot?: HubSpot;
}

/**
 * Check if a given object implements the PostReservationBodyTraveler interface.
 */
export function instanceOfPostReservationBodyTraveler(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "lastName" in value;
    isInstance = isInstance && "ageId" in value;
    isInstance = isInstance && "age" in value;
    isInstance = isInstance && "gender" in value;
    isInstance = isInstance && "countryCode" in value;
    isInstance = isInstance && "weight" in value;
    isInstance = isInstance && "height" in value;
    isInstance = isInstance && "helmet" in value;
    isInstance = isInstance && "gloves" in value;
    isInstance = isInstance && "tShirt" in value;
    isInstance = isInstance && "dateOfBirth" in value;
    isInstance = isInstance && "passport" in value;
    isInstance = isInstance && "flightNumber" in value;
    isInstance = isInstance && "arrivalTime" in value;
    isInstance = isInstance && "airline" in value;
    isInstance = isInstance && "food" in value;
    isInstance = isInstance && "dietaryRestrictions" in value;
    isInstance = isInstance && "show" in value;

    return isInstance;
}

export function PostReservationBodyTravelerFromJSON(json: any): PostReservationBodyTraveler {
    return PostReservationBodyTravelerFromJSONTyped(json, false);
}

export function PostReservationBodyTravelerFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostReservationBodyTraveler {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'ageId': AgeIdFromJSON(json['ageId']),
        'age': json['age'],
        'gender': json['gender'],
        'countryCode': json['countryCode'],
        'weight': json['weight'],
        'height': json['height'],
        'helmet': json['helmet'],
        'gloves': json['gloves'],
        'tShirt': json['tShirt'],
        'dateOfBirth': json['dateOfBirth'],
        'passport': json['passport'],
        'flightNumber': json['flightNumber'],
        'arrivalTime': json['arrivalTime'],
        'airline': json['airline'],
        'food': json['food'],
        'dietaryRestrictions': json['dietaryRestrictions'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'show': json['show'],
        'passportImage': !exists(json, 'passportImage') ? undefined : TravelerPassportImageFromJSON(json['passportImage']),
        'hubspot': !exists(json, 'hubspot') ? undefined : HubSpotFromJSON(json['hubspot']),
    };
}

export function PostReservationBodyTravelerToJSON(value?: PostReservationBodyTraveler | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'ageId': AgeIdToJSON(value.ageId),
        'age': value.age,
        'gender': value.gender,
        'countryCode': value.countryCode,
        'weight': value.weight,
        'height': value.height,
        'helmet': value.helmet,
        'gloves': value.gloves,
        'tShirt': value.tShirt,
        'dateOfBirth': value.dateOfBirth,
        'passport': value.passport,
        'flightNumber': value.flightNumber,
        'arrivalTime': value.arrivalTime,
        'airline': value.airline,
        'food': value.food,
        'dietaryRestrictions': value.dietaryRestrictions,
        'comment': value.comment,
        'show': value.show,
        'passportImage': TravelerPassportImageToJSON(value.passportImage),
        'hubspot': HubSpotToJSON(value.hubspot),
    };
}

