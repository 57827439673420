import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/pro-solid-svg-icons";
import { Dropdown } from "antd";
import { languages } from "../../../data-list";
import { useLanguage } from "../../../providers";
import assert from "assert";
import { mediaQuery } from "../../../styles";

export const PrivateLanguageSelector = (): JSX.Element => {
  const { onChangeLanguage, currentLanguageCode } = useLanguage();

  const language = languages.find(
    (language) => language.code === currentLanguageCode
  );

  assert(language, "Missing language!");

  return (
    <DropdownStyled
      placement="bottomRight"
      trigger={["click"]}
      menu={{
        items: languages
          .filter((language) => language.code !== "pt")
          .map((language) => ({
            key: language.code,
            label: (
              <WrapperItems>
                <span className="item-flag">
                  <language.Flag />
                </span>
                <span>{language.name}</span>
              </WrapperItems>
            ),
            onClick: () => onChangeLanguage(language.code),
          })),
      }}
    >
      <WrapperItems>
        <div className="item-flag">{<language.Flag />}</div>
        <FontAwesomeIcon icon={faAngleDown} />
      </WrapperItems>
    </DropdownStyled>
  );
};

const DropdownStyled = styled(Dropdown)<{ children: React.ReactNode }>`
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  font-size: ${({ theme }) => theme.font_sizes.medium};
  display: flex;
  align-items: center;
`;

const WrapperItems = styled.div`
  display: flex;
  width: auto;
  height: auto;
  box-sizing: border-box;
  gap: 0.4em;
  font-size: 1rem;
  .item-flag {
    display: flex;
    align-items: center;
    svg {
      border-radius: 0.2em;
      width: 1.7em;
      height: auto;
    }
  }

  ${mediaQuery.maxMobileS} {
    .ant-typography {
      display: none;
    }
  }
`;
