/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FirebaseFirestoreTimestamp } from './FirebaseFirestoreTimestamp';
import {
    FirebaseFirestoreTimestampFromJSON,
    FirebaseFirestoreTimestampFromJSONTyped,
    FirebaseFirestoreTimestampToJSON,
} from './FirebaseFirestoreTimestamp';

/**
 * 
 * @export
 * @interface City
 */
export interface City {
    /**
     * 
     * @type {FirebaseFirestoreTimestamp}
     * @memberof City
     */
    createAt: FirebaseFirestoreTimestamp;
    /**
     * 
     * @type {FirebaseFirestoreTimestamp}
     * @memberof City
     */
    updateAt: FirebaseFirestoreTimestamp;
    /**
     * 
     * @type {string}
     * @memberof City
     */
    updateBy: string;
    /**
     * 
     * @type {boolean}
     * @memberof City
     */
    isDeleted: boolean;
    /**
     * 
     * @type {string}
     * @memberof City
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof City
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof City
     */
    countryId: string;
}

/**
 * Check if a given object implements the City interface.
 */
export function instanceOfCity(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "createAt" in value;
    isInstance = isInstance && "updateAt" in value;
    isInstance = isInstance && "updateBy" in value;
    isInstance = isInstance && "isDeleted" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "countryId" in value;

    return isInstance;
}

export function CityFromJSON(json: any): City {
    return CityFromJSONTyped(json, false);
}

export function CityFromJSONTyped(json: any, ignoreDiscriminator: boolean): City {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createAt': FirebaseFirestoreTimestampFromJSON(json['createAt']),
        'updateAt': FirebaseFirestoreTimestampFromJSON(json['updateAt']),
        'updateBy': json['updateBy'],
        'isDeleted': json['isDeleted'],
        'id': json['id'],
        'name': json['name'],
        'countryId': json['countryId'],
    };
}

export function CityToJSON(value?: City | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createAt': FirebaseFirestoreTimestampToJSON(value.createAt),
        'updateAt': FirebaseFirestoreTimestampToJSON(value.updateAt),
        'updateBy': value.updateBy,
        'isDeleted': value.isDeleted,
        'id': value.id,
        'name': value.name,
        'countryId': value.countryId,
    };
}

