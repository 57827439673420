import { useEffect, useState } from "react";
import { breakPoints } from "../styles/constants/breakPoints";

interface Return {
  currentScreenWidth: number;
  currentScreenHeight: number;
  isMobile: boolean;
  isDevice: {
    mobile: boolean;
  };
}

export const useDevice = (): Return => {
  const [currentScreenWidth, setCurrentScreenWidth] = useState<number>(
    window.innerWidth
  );
  const [currentScreenHeight, setCurrentScreenHeight] = useState<number>(
    window.innerHeight
  );

  useEffect(() => {
    const detectWindowResize = () => {
      setCurrentScreenWidth(window.innerWidth);
      setCurrentScreenHeight(window.innerHeight);
    };

    window.addEventListener("resize", detectWindowResize);

    return () => {
      window.removeEventListener("resize", detectWindowResize);
    };
  }, []);

  const isDeviceMobile =
    !!navigator.userAgent.match("Android") ||
    !!navigator.userAgent.match("iPhone") ||
    !!navigator.userAgent.match("iPad");

  return {
    currentScreenHeight,
    currentScreenWidth,
    isMobile: currentScreenWidth < breakPoints.tablet,
    isDevice: { mobile: isDeviceMobile },
  };
};
