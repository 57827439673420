/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Languages } from './Languages';
import {
    LanguagesFromJSON,
    LanguagesFromJSONTyped,
    LanguagesToJSON,
} from './Languages';

/**
 * 
 * @export
 * @interface Stop
 */
export interface Stop {
    /**
     * 
     * @type {string}
     * @memberof Stop
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Stop
     */
    tourDetailDayId: string;
    /**
     * 
     * @type {Languages}
     * @memberof Stop
     */
    name: Languages;
    /**
     * 
     * @type {Languages}
     * @memberof Stop
     */
    description: Languages;
    /**
     * 
     * @type {string}
     * @memberof Stop
     */
    duration: string;
    /**
     * 
     * @type {string}
     * @memberof Stop
     */
    admissionIncluded: StopAdmissionIncludedEnum;
}


/**
 * @export
 */
export const StopAdmissionIncludedEnum = {
    Yes: 'yes',
    No: 'no',
    Empty: ''
} as const;
export type StopAdmissionIncludedEnum = typeof StopAdmissionIncludedEnum[keyof typeof StopAdmissionIncludedEnum];


/**
 * Check if a given object implements the Stop interface.
 */
export function instanceOfStop(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "tourDetailDayId" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "duration" in value;
    isInstance = isInstance && "admissionIncluded" in value;

    return isInstance;
}

export function StopFromJSON(json: any): Stop {
    return StopFromJSONTyped(json, false);
}

export function StopFromJSONTyped(json: any, ignoreDiscriminator: boolean): Stop {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'tourDetailDayId': json['tourDetailDayId'],
        'name': LanguagesFromJSON(json['name']),
        'description': LanguagesFromJSON(json['description']),
        'duration': json['duration'],
        'admissionIncluded': json['admissionIncluded'],
    };
}

export function StopToJSON(value?: Stop | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'tourDetailDayId': value.tourDetailDayId,
        'name': LanguagesToJSON(value.name),
        'description': LanguagesToJSON(value.description),
        'duration': value.duration,
        'admissionIncluded': value.admissionIncluded,
    };
}

