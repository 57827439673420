import React from "react";
import styled, { css, DefaultTheme } from "styled-components";
import { tint } from "polished";
import { Button as ButtonAntd, ButtonProps } from "antd";

type PickButtonProps =
  | "children"
  | "onClick"
  | "htmlType"
  | "block"
  | "type"
  | "size"
  | "icon"
  | "className"
  | "danger"
  | "style"
  | "id";

interface Props extends Pick<ButtonProps, PickButtonProps>, StyledProps {
  disabled?: boolean;
  loading?: boolean;
  ghost?: boolean;
}

export const Button = ({
  children,
  disabled,
  loading,
  onClick,
  htmlType,
  block,
  type,
  size,
  icon,
  className,
  styled,
  danger,
  ghost,
  id,
}: Props): JSX.Element => (
  <ButtonStyled
    danger={danger}
    styled={styled}
    type={type}
    className={className}
    icon={icon}
    size={size}
    block={block}
    htmlType={htmlType}
    disabled={disabled}
    loading={loading}
    onClick={onClick}
    ghost={ghost}
    id={id}
  >
    {children}
  </ButtonStyled>
);

interface StyledProps {
  styled?: {
    background?: (theme: DefaultTheme) => string;
    color?: (theme: DefaultTheme) => string;
  };
}

const ButtonStyled = styled(ButtonAntd)<StyledProps>`
  text-transform: uppercase;

  ${({ theme, styled: { background, color = () => "#fff" } = {} }) =>
    background &&
    css`
      color: ${color(theme)};
      background: ${background(theme)};
      border-color: ${background(theme)};

      &:hover,
      &:focus {
        color: ${color(theme)} !important;
        background: ${tint(0.25, background(theme))};
        border-color: ${tint(0.25, background(theme))} !important;
      }
    `}
`;
