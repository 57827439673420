import React from "react";
import styled, { css, DefaultTheme } from "styled-components";
import { keyframes } from "../styles";
import { Space as SpaceAntd, Typography } from "antd";

const { Text } = Typography;

interface Props {
  required?: boolean;
  name?: string;
  helperText?: string;
  error?: boolean;
  label?: string;
  children?: React.ReactNode;
}

export const Group = ({
  label,
  helperText,
  required,
  error = true,
  children,
}: Props): JSX.Element => (
  <div>
    <Container error={error}>
      <label className="legend-title">{label}</label>
      <SpaceStyled size="small" direction="vertical" required={required}>
        {children}
      </SpaceStyled>
    </Container>
    {helperText && (
      <ErrorItem
        fontColor={(theme) => (error ? theme.colors.error : undefined)}
        fontSize="small"
        error={error}
      >
        {helperText}
      </ErrorItem>
    )}
  </div>
);

const Container = styled.fieldset<Props>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${({ theme }) => theme.border_radius.x_small};
  border: solid 1px ${({ theme }) => theme.colors.bordered};
  ${({ error, theme }) => (error ? theme.colors.error : theme.colors.light)};
  position: relative;
  padding: 1rem;
  margin-top: 5px;

  .legend-title {
    position: absolute;
    top: -12px;
    left: 10px;
    z-index: 100;
    pointer-events: none;
    display: flex;
    background-color: white;
    font-weight: ${({ theme }) => theme.font_weight.large};
    font-size: ${({ theme }) => theme.font_sizes.small};
    padding: 0 ${({ theme }) => theme.border_radius.xx_small};
  }
`;

const SpaceStyled = styled(SpaceAntd)<Props>`
  width: 100%;
`;

interface ErrorItemProps {
  fontColor?: (theme: DefaultTheme) => string | undefined;
  fontSize:
    | "xx_small"
    | "x_small"
    | "small"
    | "medium"
    | "large"
    | "x_large"
    | "xx_large"
    | "xxx_large";
  error: boolean;
}

const ErrorItem = styled(Text)<ErrorItemProps>`
  ${({ theme, fontColor, fontSize = "small", error }) => css`
    font-size: ${theme.font_sizes[fontSize]};
    ${fontColor &&
    css`
      color: ${fontColor(theme)};
    `},
    ${error &&
    css`
      animation: ${keyframes.shake} 340ms;
    `}
  `}
`;
