/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Extra } from './Extra';
import {
    ExtraFromJSON,
    ExtraFromJSONTyped,
    ExtraToJSON,
} from './Extra';

/**
 * 
 * @export
 * @interface PostExtrasBody
 */
export interface PostExtrasBody {
    /**
     * 
     * @type {Array<Extra>}
     * @memberof PostExtrasBody
     */
    extras: Array<Extra>;
}

/**
 * Check if a given object implements the PostExtrasBody interface.
 */
export function instanceOfPostExtrasBody(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "extras" in value;

    return isInstance;
}

export function PostExtrasBodyFromJSON(json: any): PostExtrasBody {
    return PostExtrasBodyFromJSONTyped(json, false);
}

export function PostExtrasBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostExtrasBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'extras': ((json['extras'] as Array<any>).map(ExtraFromJSON)),
    };
}

export function PostExtrasBodyToJSON(value?: PostExtrasBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'extras': ((value.extras as Array<any>).map(ExtraToJSON)),
    };
}

