/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Languages } from './Languages';
import {
    LanguagesFromJSON,
    LanguagesFromJSONTyped,
    LanguagesToJSON,
} from './Languages';

/**
 * 
 * @export
 * @interface Included
 */
export interface Included {
    /**
     * 
     * @type {Languages}
     * @memberof Included
     */
    description: Languages;
}

/**
 * Check if a given object implements the Included interface.
 */
export function instanceOfIncluded(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "description" in value;

    return isInstance;
}

export function IncludedFromJSON(json: any): Included {
    return IncludedFromJSONTyped(json, false);
}

export function IncludedFromJSONTyped(json: any, ignoreDiscriminator: boolean): Included {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': LanguagesFromJSON(json['description']),
    };
}

export function IncludedToJSON(value?: Included | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': LanguagesToJSON(value.description),
    };
}

