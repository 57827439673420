/* tslint:disable */
/* eslint-disable */
/**
 * functions
 * Cloud Functions for Firebase
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Languages } from './Languages';
import {
    LanguagesFromJSON,
    LanguagesFromJSONTyped,
    LanguagesToJSON,
} from './Languages';
import type { Stop } from './Stop';
import {
    StopFromJSON,
    StopFromJSONTyped,
    StopToJSON,
} from './Stop';

/**
 * 
 * @export
 * @interface TourDetailsDay
 */
export interface TourDetailsDay {
    /**
     * 
     * @type {string}
     * @memberof TourDetailsDay
     */
    id: string;
    /**
     * 
     * @type {Languages}
     * @memberof TourDetailsDay
     */
    name: Languages;
    /**
     * 
     * @type {Array<Stop>}
     * @memberof TourDetailsDay
     */
    stops: Array<Stop>;
}

/**
 * Check if a given object implements the TourDetailsDay interface.
 */
export function instanceOfTourDetailsDay(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "stops" in value;

    return isInstance;
}

export function TourDetailsDayFromJSON(json: any): TourDetailsDay {
    return TourDetailsDayFromJSONTyped(json, false);
}

export function TourDetailsDayFromJSONTyped(json: any, ignoreDiscriminator: boolean): TourDetailsDay {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': LanguagesFromJSON(json['name']),
        'stops': ((json['stops'] as Array<any>).map(StopFromJSON)),
    };
}

export function TourDetailsDayToJSON(value?: TourDetailsDay | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': LanguagesToJSON(value.name),
        'stops': ((value.stops as Array<any>).map(StopToJSON)),
    };
}

